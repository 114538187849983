import validation from '../../../../../../validation'
import { ApplicableLawState } from '../types'

const getListParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
  lawId: validation.rules.string().required().label('Law Id'),
  state: validation.rules.string().oneOf([
    ApplicableLawState.NO_ACTION,
    ApplicableLawState.BEST_PRACTICE_REQUIREMENTS,
    ApplicableLawState.IMPROVE_LEGAL_PRACTICE,
    ApplicableLawState.DISCLOSURE_REQUIREMENTS,
  ]).label('State of privacyIssues'),
}

const getListParams = {
  rules: getListParamRules,
  schema: validation.schema(getListParamRules),
}

const getListQueryRules = {
  offset: validation.rules.string().integer().label('Offset'),
  limit: validation.rules.string().integer().label('Limit'),
}

const getListQuery = {
  rules: getListQueryRules,
  schema: validation.schema(getListQueryRules),
}

// eslint-disable-next-line import/prefer-default-export
export const getList = {
  params: getListParams,
  query: getListQuery,
}
