import validation from '../../../../../validation'
import rules from '../../../../../validation/rules'

const getParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
  recommendedActionId: validation.rules.string().required().label('Recommended Action ID'),
}

const getListParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
}

const getListQueryRules = { privacyIssueId: validation.rules.string().required().label('Privacy Issue ID') }

const putParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
  recommendedActionId: validation.rules.string().required().label('Recommended Action ID'),
}

const putBodyRules = { answerIds: rules.array().of(rules.string()).required().label('Answer IDs') }

const getParams = {
  rules: getParamRules,
  schema: validation.schema(getParamRules),
}

const getListParams = {
  rules: getListParamRules,
  schema: validation.schema(getListParamRules),
}

const getListQuery = {
  rules: getListQueryRules,
  schema: validation.schema(getListQueryRules),
}

const putParams = {
  rules: putParamRules,
  schema: validation.schema(putParamRules),
}

const putBody = {
  rules: putBodyRules,
  schema: validation.schema(putBodyRules),
}

export const get = { params: getParams }

export const getList = {
  params: getListParams,
  query: getListQuery,
}

export const put = {
  params: putParams,
  body: putBody,
}
