import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormHelperText,
  Link,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { api } from '@common-sense-privacy/common'

import { useHandleFormApiErrors } from '@common-sense-privacy/ui'

import useSession from '@/hooks/useSession'

import FormField from '@/components/FormField'

import Form from '@/components/Form'

import { useUpdateContactDetailsMutation } from '@/services/users/contact'

import { useAlert } from '@/context/AlertContext'

import { useGetRolesQuery } from '@/services/roles'
import useNavigate from '@/hooks/useNavigate'
import { setSession } from '@/state/slices/session'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

import {
  FormValues,
  OnSubmit,
  Role,
  UserOrganizationRole,
} from './types'

function EditContact(): React.ReactElement {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleFormApiErrors = useHandleFormApiErrors()
  const [
    patchContactDetails,
    { isLoading },
  ] = useUpdateContactDetailsMutation()
  const { user } = useSession()
  const { setAlert } = useAlert()
  const {
    data: roles,
    isLoading: isRolesLoading,
  } = useGetRolesQuery(undefined, { refetchOnMountOrArgChange: true })

  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    if (!user?.id) {
      return
    }
    patchContactDetails({
      body: values,
      params: { id: user.id },
    })
      .unwrap().then(response => {
        navigate('/account')
        dispatch(setSession(response.data))
        setAlert({
          description: response.message,
          type: 'success',
        })
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    navigate,
    dispatch,
    handleFormApiErrors,
    patchContactDetails,
    setAlert,
    user?.id,
  ])
  const { rules } = api.endpoints.user.contact.validation.patch.body
  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    jobTitle: user?.jobTitle || '',
    organizationRoles: user?.organizations[0]?.roles?.map((role: UserOrganizationRole) => role.role.id) || [],
  }

  return (
    <DocumentWrapper title='Common Sense Privacy | My Account'>
      <ScreenWrapper>
        <Container>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/account' variant='body2'>
              Account
            </Link>
            <Typography color='text.primary' variant='body2'>Edit Contact Details</Typography>
          </Breadcrumbs>
          <Typography variant='h1'>Contact details</Typography>
          <Typography variant='intro' mb={4}>Edit your contact information</Typography>
          <Divider />
        </Container>
        <Container>
          <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={rules} maxWidth='sm' spacing={4} mt={6}>
            <FormField name='firstName' label='First Name' variant='outlined' value={user?.firstName} />
            <FormField name='lastName' label='Last Name' variant='outlined' value={user?.lastName} />
            <FormField name='jobTitle' label='Job Title' variant='outlined' value={user?.jobTitle} />
            {roles && roles.data && user && user?.organizations[0] ? (
              <FormField
                type='select'
                name='organizationRoles'
                labelId='roles-select-label'
                id='roles-select'
                label='Functional Roles'
                inputProps={{ 'data-testid': 'contact-functional-roles' }}
                multiple={true}
              >
                {(roles?.data || []).map((endpointRole: Role) => (
                  <MenuItem
                    key={endpointRole.id}
                    value={endpointRole.id}

                  >
                    {endpointRole.name}
                  </MenuItem>
                ))}
              </FormField>
            ) : undefined}

            <FormHelperText id='password-helper-text'>Choose all functional roles you fill at your company. These should represent areas where you have knowledge, and feel qualified to answer related questions.</FormHelperText>

            <Stack direction='row' spacing={2}>
              <Button
                variant='contained'
                type='submit'
                disabled={isLoading || isRolesLoading}
              >Save
              </Button>
              <Button variant='outlined' onClick={() => navigate('/account')}>Cancel</Button>
            </Stack>
          </Form>
          <Stack mt={8}>
            <Divider />
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(EditContact)
