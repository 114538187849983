import validation from '../../../../../validation'
import rules from '../../../../../validation/rules'

const getListParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
}

const getListQueryRules = {
  category: rules.string().oneOf([
    'top-priority',
    'easy-fix',
    'high-priority-fix',
    'improve-practice',
  ]).label('Category'),
  offset: rules.string().integer().label('Offset'),
  limit: rules.string().integer().label('Limit'),
}

const getListParams = {
  rules: getListParamRules,
  schema: validation.schema(getListParamRules),
}

const getListQuery = {
  rules: getListQueryRules,
  schema: validation.schema(getListQueryRules),
}

const putBodyRules = {
  answers: rules.array().of(rules.lazy(value => typeof value === 'object'
    ? rules.object() : rules.string())).label('Answers'),
}

const putParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
  privacyIssueId: validation.rules.string().required().label('Privacy Issue ID'),
}

const putParams = {
  rules: putParamRules,
  schema: validation.schema(putParamRules),
}

const putBody = {
  rules: putBodyRules,
  schema: validation.schema(putBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const put = {
  body: putBody,
  params: putParams,
}

export const get = { params: putParams }

export const getList = {
  params: getListParams,
  query: getListQuery,
}
