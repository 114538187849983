import React from 'react'
import {
  Box,
  Button,
  capitalize,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  Link,
} from '@mui/material'

import { Link as RouterLink } from 'react-router-dom'

import useSession, {
  useCurrentRoleNames,
  useOrganizationSubscription,
  useDefaultPaymentMethod,
  useCurrentSubscriptionPlan,
} from '@/hooks/useSession'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import { subscriptionHasFailedPayments } from '@/utils/stripe'

function Account(): React.ReactElement {
  const sessionState = useSession()
  const currentRoles = useCurrentRoleNames()
  const subscription = useOrganizationSubscription()
  const defaultPaymentMethod = useDefaultPaymentMethod()
  const currentSubscriptionPlan = useCurrentSubscriptionPlan()

  const product = sessionState.user?.organizations[0]?.organization.products?.[0]
  const organization = sessionState.user?.organizations[0]?.organization
  const hasFailedPayments = subscription && subscriptionHasFailedPayments(subscription)

  return (
    <DocumentWrapper title='Common Sense Privacy | My Account'>
      <ScreenWrapper>
        <Container>
          <Typography variant='h1'>My Account</Typography>
          <Typography variant='intro' mb={hasFailedPayments ? 2 : 4}>Edit your contact information, notification preferences and password</Typography>
          {hasFailedPayments && (
            <Typography variant='body2' color='error' mb={2}>
              * Your recent payment failed to process. Please{' '}
              <Link href='/account/edit-payment-method' variant='body2' color='error'>
                update your payment method
              </Link>.
            </Typography>
          )}
          <Divider />
        </Container>
        <Container>

          <Grid container={true} spacing={2} mt={2} mb={4}>
            <Grid item={true} xs={12} md={10}>
              <Stack spacing={3}>
                <Typography variant='h5'>Contact Details</Typography>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>First Name:</Typography>
                  <Typography variant='body2'>{sessionState.user?.firstName}</Typography>
                </Box>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Last Name:</Typography>
                  <Typography variant='body2'>{sessionState.user?.lastName}</Typography>
                </Box>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Job Title:</Typography>
                  <Typography variant='body2'>{sessionState.user?.jobTitle}</Typography>
                </Box>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Functional Roles:</Typography>
                  <Typography variant='body2'>{currentRoles}</Typography>
                </Box>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Email:</Typography>
                  <Typography variant='body2'>{sessionState.user?.email}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <Box
                display='flex'
                justifyContent={{
                  xs: 'flex-start',
                  md: 'flex-end',
                }}
              >
                <Button to='/account/edit-contact' component={RouterLink} variant='outlined' sx={{ width: 'auto' }}>Edit</Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />

          <Grid container={true} spacing={2} mt={2} mb={4}>
            <Grid item={true} xs={12} md={10}>
              <Stack spacing={3}>
                <Typography variant='h5'>Organization Details</Typography>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Organization Name:</Typography>
                  <Typography variant='body2'>{organization?.name}</Typography>
                </Box>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Organization Size:</Typography>
                  <Typography variant='body2'>{organization?.size}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <Box
                display='flex'
                justifyContent={{
                  xs: 'flex-start',
                  md: 'flex-end',
                }}
              >
                <Button to='/account/edit-organization' component={RouterLink} variant='outlined' sx={{ width: 'auto' }}>Edit</Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />

          <Grid container={true} spacing={2} mt={2} mb={4}>
            <Grid item={true} xs={12} md={10}>
              <Stack spacing={3}>
                <Typography variant='h5'>Product Settings</Typography>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Product Name:</Typography>
                  <Typography variant='body2'>{product?.name}</Typography>
                </Box>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Product Type:</Typography>
                  <Typography variant='body2'>{product?.type.name}</Typography>
                </Box>
                {!!product?.distributionMethods?.length && (
                  <Box>
                    <Typography variant='body2' fontWeight='bold'>Product Distribution Methods:</Typography>
                    <Typography variant='body2'>{product.distributionMethods.map(distMethod => distMethod.name).join(', ')}</Typography>
                  </Box>
                )}
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Product Categories:</Typography>
                  <Typography variant='body2'>{product?.categories.map(category => category.name).join(', ')}</Typography>
                </Box>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Product Privacy Policy Introduction:</Typography>
                  <Typography variant='body2'>{product?.privacyPolicyIntroduction}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <Box
                display='flex'
                justifyContent={{
                  xs: 'flex-start',
                  md: 'flex-end',
                }}
              >
                <Button to='/account/edit-product' component={RouterLink} variant='outlined' sx={{ width: 'auto' }}>Edit</Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />

          <Grid container={true} spacing={2} mt={2} mb={4}>
            <Grid item={true} xs={12} md={10}>
              <Stack spacing={3}>
                <Typography variant='h5'>Plan</Typography>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Current Plan:</Typography>
                  <Typography variant='body2'>{currentSubscriptionPlan?.product.name === 'Starter' ? 'Get Started' : currentSubscriptionPlan?.product.name}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <Box
                display='flex'
                justifyContent={{
                  xs: 'flex-start',
                  md: 'flex-end',
                }}
              >
                <Button to='/account/edit-plan' component={RouterLink} variant='outlined' sx={{ width: 'auto' }}>Edit</Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />

          <Grid container={true} spacing={2} mt={2} mb={4}>
            <Grid item={true} xs={12} md={10}>
              <Stack spacing={3}>
                <Typography variant='h5'>Payment Method</Typography>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>Credit Card:</Typography>
                  <Typography variant='body2'>{capitalize(defaultPaymentMethod?.card?.brand || '')} {defaultPaymentMethod?.card?.last4}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <Box
                display='flex'
                justifyContent={{
                  xs: 'flex-start',
                  md: 'flex-end',
                }}
              >
                <Button
                  to='/account/edit-payment-method'
                  component={RouterLink}
                  variant='outlined'
                  sx={{ width: 'auto' }}
                >Edit
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />

          <Grid container={true} spacing={2} mt={2} mb={4}>
            <Grid item={true} xs={12} md={10}>
              <Stack spacing={3}>
                <Typography variant='h5'>Password</Typography>
                <Box>
                  <Typography variant='body2' fontWeight='bold'>********</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <Box
                display='flex'
                justifyContent={{
                  xs: 'flex-start',
                  md: 'flex-end',
                }}
              >
                <Button to='/account/edit-password' component={RouterLink} variant='outlined' sx={{ width: 'auto' }}>Edit</Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />

          <Grid container={true} spacing={2} mt={2} mb={4}>
            <Grid item={true} xs={12} md={10}>
              <Stack spacing={3}>
                <Typography variant='h5'>Notifications</Typography>
                <Stack my={3}>
                  <Typography variant='body2' fontWeight='bold'>Email</Typography>
                  <Box>
                    <Typography variant='body2'>{sessionState.user?.isEmailNotificationEnabled ? 'On' : 'Off'}</Typography>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <Box
                display='flex'
                justifyContent={{
                  xs: 'flex-start',
                  md: 'flex-end',
                }}
              >
                <Button to='/account/edit-notifications' component={RouterLink} variant='outlined' sx={{ width: 'auto' }}>Edit</Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Account)
