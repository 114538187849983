import type { InferType } from '../../../../../validation'

import { ApiSuccess } from '../../../../ApiSuccess/types'
import { Law } from '../privacyIssue/types'

import * as validation from './validation'

export type GetRequestParams = InferType<typeof validation.get.params.schema>

export type GetListParams = InferType<typeof validation.getList.params.schema>

export type PaginatedProductApplicableLaws = {
    items: Law[] | undefined,
    offset: number,
    limit: number,
    total: number,
}

export type GetListRequest = {
    params: GetListParams,
}

export type GetRequest = {
    params: GetRequestParams,
}

export enum ApplicableLawState {
    NO_ACTION = 'noAction',
    BEST_PRACTICE_REQUIREMENTS = 'bestPracticeRequirements',
    IMPROVE_LEGAL_PRACTICE = 'improveLegalPractice',
    DISCLOSURE_REQUIREMENTS = 'disclosureRequirements'
}

export type GetListResponseBody = ApiSuccess<PaginatedProductApplicableLaws>

export type GetResponseBody = ApiSuccess<Law>
