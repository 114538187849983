import validation from '../../../../../../validation'

const getParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
  privacyIssueId: validation.rules.string().required().label('Privacy Issue ID'),
}

const getQueryRules = {
  metric: validation.rules.array().of(validation.rules.string()
    .oneOf([ 'topicScore' ]).required()).required().min(1)
    .label('Metric'),
}

const getQuery = {
  rules: getQueryRules,
  schema: validation.schema(getQueryRules),
}

const getParams = {
  rules: getParamRules,
  schema: validation.schema(getParamRules),
}

// eslint-disable-next-line import/prefer-default-export
export const get = {
  params: getParams,
  query: getQuery,
}
