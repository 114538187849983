import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Button,
  Divider,
  Stack,
} from '@mui/material'

import { validation } from '@common-sense-privacy/common'

import { FormikProps } from 'formik'

import Form from '@/components/Form'

import DateInput from './InputFields/DateInput'
import RadioGroupList from './InputFields/RadioGroupList'
import NamedRadioGroupList from './InputFields/NamedRadioGroupList'
import CheckboxList from './InputFields/CheckboxList'
import VendorList from './InputFields/VendorList'

import {
  PrivacyIssueAnswerInputField,
  PrivacyIssueAnswerInputFieldOption,
  ProductPrivacyIssue,
  FormValues,
  VendorListValues,
} from './types'
import FreeTextInput from './InputFields/FreeText'

function PrivacyIssueQuestion({
  inputId,
  isLoading = false,
  onSave,
  productPrivacyIssue,
  saveButtonText = 'Save',
  showDividerAboveButtons = false,
} : {
  inputId: string,
  isLoading?: boolean,
  onSave: (answerIds: string[] | VendorListValues) => void,
  productPrivacyIssue: ProductPrivacyIssue,
  saveButtonText?: string,
  showDividerAboveButtons?: boolean,
}): React.ReactElement {
  const { privacyIssue } = productPrivacyIssue

  const isMultiple = useMemo(() => privacyIssue.type === 'multi-select', [ privacyIssue.type ])

  const [
    answers,
    setAnswers,
  ] = useState<(string | VendorListValues['vendorList'][0])[]>([])

  useEffect(() => {
    if (isLoading || !productPrivacyIssue?.answers) {
      return
    }

    if (productPrivacyIssue.privacyIssue.type === 'multi-select' && productPrivacyIssue?.answers?.length === 0) {
      const defaultNoAnswerIds = productPrivacyIssue.privacyIssue.answerInputFields
        .flatMap((answerInputField: PrivacyIssueAnswerInputField) => answerInputField?.privacyIssueAnswerInputFieldOptions?.find((option: PrivacyIssueAnswerInputFieldOption) => option.internalValue === 'No')?.id)
        .filter((answerId: string | undefined) => answerId) as string[]

      if (defaultNoAnswerIds && defaultNoAnswerIds.length > 0) {
        setAnswers(defaultNoAnswerIds)

        return
      }
    }

    const answers = productPrivacyIssue.answers
      .map(answer => {
        switch (productPrivacyIssue.privacyIssue.type) {
          case 'date':
          case 'vendor':
          case 'free-text':
            return answer.userEnteredValue || ''
          default:
            return answer.answer?.id
        }
      }).filter(id => !!id)
    setAnswers(answers.flat())
  }, [
    isLoading,
    productPrivacyIssue,
  ])

  const onClearForm = useCallback(() => {
    setAnswers([])
  }, [])

  // const handleOnSaveForLater = () => {
  //   // TODO: submit
  // }

  const getNewAnswers = (answerId: string, checked: boolean) => {
    if (!isMultiple) {
      return [ answerId ]
    }

    if (checked) {
      return [
        ...answers,
        answerId,
      ]
    }

    return answers.filter(id => id !== answerId)
  }

  const handleOnRadioChange = (answerId: string, checked: boolean) => {
    setAnswers(getNewAnswers(answerId, checked))
  }

  const handleOnCheckboxChange = (inputFieldId: string, checked: boolean) => {
    const currentAnswers = answers
    const inputField = privacyIssue.answerInputFields.find((inputField: PrivacyIssueAnswerInputField) => inputField.id === inputFieldId) as PrivacyIssueAnswerInputField
    const foundOption = inputField.privacyIssueAnswerInputFieldOptions.find((option: PrivacyIssueAnswerInputFieldOption) => {
      if (checked) {
        return option.internalValue === 'Yes'
      }

      return option.internalValue === 'No'
    }) as PrivacyIssueAnswerInputFieldOption
    const removeOption = inputField.privacyIssueAnswerInputFieldOptions.find((option: PrivacyIssueAnswerInputFieldOption) => option.id !== foundOption.id) as PrivacyIssueAnswerInputFieldOption
    const newAnswers = currentAnswers.filter(id => id !== removeOption.id)
    newAnswers.push(foundOption.id)

    setAnswers(newAnswers)
  }

  const isCheckBoxChecked = (inputFieldId: string) => {
    const inputField = privacyIssue?.answerInputFields?.find((inputField: PrivacyIssueAnswerInputField) => inputField.id === inputFieldId) as PrivacyIssueAnswerInputField
    const currentAnswers = answers
    const options = inputField.privacyIssueAnswerInputFieldOptions as PrivacyIssueAnswerInputFieldOption[]
    const answer = options.find((option: PrivacyIssueAnswerInputFieldOption) => currentAnswers.includes(option.id))

    if (answer) {
      return answer.internalValue === 'Yes'
    }

    return false
  }

  const handleOnMultiRadioChange = (inputFieldId: string, optionId: string) => {
    const inputField = privacyIssue.answerInputFields.find((field: PrivacyIssueAnswerInputField) => field.id === inputFieldId)
    if (!inputField) return
    const selectedOption = inputField.privacyIssueAnswerInputFieldOptions
      .find((option: PrivacyIssueAnswerInputFieldOption) => option.id === optionId)
    const otherOption = inputField.privacyIssueAnswerInputFieldOptions
      .find((option: PrivacyIssueAnswerInputFieldOption) => option.id !== optionId)

    const newAnswers = otherOption ? answers.filter(answer => answer !== otherOption.id) : answers.slice()
    if (selectedOption && !answers.includes(selectedOption.id)) {
      newAnswers.push(selectedOption.id)
    }

    setAnswers(newAnswers)
  }

  const renderInputField = (type: string, formik: FormikProps<FormValues | VendorListValues>) => {
    switch (type) {
      case 'date':
        return <DateInput answers={answers as string[]} onChange={setAnswers} />
      case 'matrix':
        return (
          <NamedRadioGroupList
            inputFields={privacyIssue.answerInputFields}
            answers={answers as string[]}
            onChange={handleOnMultiRadioChange}
          />
        )
      case 'multi-select':
        return (
          <CheckboxList
            inputFields={privacyIssue?.answerInputFields}
            isCheckBoxChecked={isCheckBoxChecked}
            onChange={handleOnCheckboxChange}
          />
        )
      case 'free-text':
        return (
          <FreeTextInput
            answer={answers[0] as string}
            onChange={(value: string) => {
              setAnswers([ value ])
            }}
          />
        )
      case 'vendor':
        return (
          <VendorList
            inputFields={privacyIssue?.answerInputFields}
            hasAnswers={!!productPrivacyIssue?.answers?.length}
            answers={answers as VendorListValues['vendorList']}
            onChange={newAnswers => setAnswers(newAnswers)}
            formik={formik as FormikProps<VendorListValues>}
          />
        )
      default:
        return (
          <RadioGroupList
            inputFields={privacyIssue?.answerInputFields}
            answers={answers as string[]}
            name={inputId}
            onChange={handleOnRadioChange}
          />
        )
    }
  }

  const getValidationRulesForVendorTypeQuestion = () => ({
    vendorList: validation.rules.array().of(validation.rules.object().shape({
      description: validation.rules.string().optional(),
      name: validation.rules.string().optional(),
      url: validation.rules.string().url().optional(),
    })),
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getValidationRulesForPrivacyIssueType = (type: string): Record<string, any> => {
    switch (type) {
      case 'vendor':
        return getValidationRulesForVendorTypeQuestion()
      default:
        return {}
    }
  }

  const handleFormSubmit = (values: FormValues | VendorListValues) => {
    if (privacyIssue.type === 'vendor') {
      onSave(values)
    }
    else {
      onSave(answers as string[])
    }
  }

  const getInitialValues = () => {
    if (privacyIssue.type !== 'vendor') {
      return []
    }
    const initialValues = answers.length > 0 ? { vendorList: answers as VendorListValues['vendorList'] } : {
      vendorList: [
        {
          name: '',
          url: '',
          description: '',
        },
      ],
    }

    return initialValues
  }

  return (
    <Stack spacing={2}>
      <Form<FormValues | VendorListValues>
        enableReinitialize={true}
        validateOnBlur={true}
        onSubmit={handleFormSubmit}
        initialValues={getInitialValues()}
        rules={getValidationRulesForPrivacyIssueType(privacyIssue.type)}
      >
        {formik => (
          <>
            <Stack pb={3}>
              {renderInputField(privacyIssue.type, formik)}
            </Stack>
            <Stack pb={3}>
              {showDividerAboveButtons && <Divider />}
            </Stack>
            <Stack direction='row' spacing={2}>
              <Button
                sx={{ width: 'auto' }}
                type='submit'
              >{saveButtonText}
              </Button>
              <Button
                variant='outlined'
                sx={{ width: 'auto' }}
                disabled={!answers.length}
                onClick={onClearForm}
              >
                Clear Response
              </Button>

            </Stack>

          </>
        )}
      </Form>
    </Stack>
  )
}

export default React.memo(PrivacyIssueQuestion)
