import validation from '../../../../validation'
import rules from '../../../../validation/rules'

const patchBodyRules = {
  currentPassword: rules.string().required().label('Current Password'),
  newPassword: rules.string().password().required().label('New Password'),
  confirmPassword: rules.string().required().oneOf([ rules.ref('newPassword') ], 'Passwords must match').label('Confirm Password'),
}
const patchBody = {
  rules: patchBodyRules,
  schema: validation.schema(patchBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const patch = { body: patchBody }
