"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function url() {
    /* eslint-disable */
    return this.test('urlValidation', 'Please enter a valid URL.', (value) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol (optional)
            '(www\\.)?' + // optional www
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        if (value && !pattern.test(value)) {
            return false;
        }
        return true;
    });
    /* eslint-enable */
}
exports.default = url;
