import validation from '../../../../validation'

const bodyRules = {
  paymentMethodId: validation.rules.string().required().label('Payment Method Id'),
  stripeCustomerId: validation.rules.string().required().label('Stripe Customer Id'),
}

// eslint-disable-next-line import/prefer-default-export
export const body = {
  rules: bodyRules,
  schema: validation.schema(bodyRules),
}
