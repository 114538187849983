import React from 'react'
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'

import { Props } from './types'

export default function CheckboxList({
  inputFields,
  isCheckBoxChecked,
  onChange,
}: Props) {
  return (
    <FormControl sx={{ marginTop: 0 }}>
      <FormGroup>
        {inputFields.map(inputField => (
          <FormControlLabel
            key={inputField.id}
            value={inputField.id}
            control={(
              <Checkbox
                data-testid={`check-${inputField.id}`}
                checked={isCheckBoxChecked(inputField.id)}
              />
            )}
            label={inputField?.privacyIssueAnswerInputFieldOptions.at(0)?.value}
            onChange={(event: React.SyntheticEvent) => onChange(
              inputField.id,
              (event.target as HTMLInputElement).checked,
            )}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}
