import * as Yup from 'yup'

import rules from './rules'

const { object: schema } = Yup

export default {
  ...Yup,
  rules,
  schema,
  ValidationError: Yup.ValidationError,
}

export * from './types'
