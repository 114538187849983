import React from 'react'

import {
  Box,
  Button,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material'

import { Props } from './types'

function PlanCards({
  tagLine,
  planTypeTitle,
  price,
  listItems,
  isActive,
  interval,
  showContinueButton,
  showSelectButton,
  scheduledForDowngrade,
  downgradeHelperText,
  onContinue,
  onSelect,
}: Props): React.ReactElement {
  const planCardStyles = {
    padding: 3,
    borderRadius: 4,
    border: 1,
    borderColor: '#33A544',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }
  const listStyles = {
    listStyleType: 'disc',
    pl: 2,
  }
  const listItemStyles = {
    display: 'list-item',
    fontSize: 16,
    padding: 0,
    marginBottom: 1,
  }

  return (
    <Box sx={{ ...planCardStyles }}>
      <Stack spacing={1}>
        <Box minHeight={60}>
          <Typography fontWeight='bold' pb={2}>{tagLine}</Typography>
        </Box>
        <Box>
          <Typography variant='h2'>{planTypeTitle}</Typography>
          {price !== 'Free' && (
            <Stack direction='row'>
              <Typography variant='h6'>{price}</Typography>
              {interval && <Typography ml={0.5}>{`/ ${interval}`}</Typography>}
            </Stack>
          )}
        </Box>
        <List sx={{ ...listStyles }}>
          {listItems.map(item => (
            <ListItem key={item} sx={{ ...listItemStyles }}>{item}</ListItem>
          ))}
        </List>
      </Stack>
      {(isActive || scheduledForDowngrade) && (
        <Stack mt='auto' alignItems={{ md: 'center' }}>
          <Box sx={{
            backgroundColor: 'primary.background',
            padding: 1,
            textAlign: 'center',
            borderRadius: 25,
            width: '100%',
            maxWidth: 240,
          }}
          >
            <Typography>{scheduledForDowngrade ? downgradeHelperText : '- Your Plan -' }</Typography>
          </Box>
        </Stack>
      )}
      <Stack
        spacing={2}
        mt='auto'
        alignItems={{ md: 'center' }}
      >
        {showContinueButton && <Button variant='outlined' onClick={onContinue}>Continue</Button>}
        {showSelectButton && <Button variant='outlined' onClick={onSelect}>Select</Button>}
      </Stack>
    </Box>
  )
}

export default React.memo(PlanCards)
