import React from 'react'
import {
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Box,
  Divider,
  Radio,
} from '@mui/material'

import { Props } from './types'

export default function NamedRadioGroupList({
  inputFields,
  answers,
  onChange,
}: Props) {
  return (
    <Stack spacing={4}>
      {inputFields.map(inputField => (
        <FormControl key={inputField.id}>
          <FormLabel>{inputField.label}</FormLabel>
          <RadioGroup sx={{ paddingLeft: '12px' }}>
            {inputField.privacyIssueAnswerInputFieldOptions.map(option => (
              <FormControlLabel
                key={option.id}
                value={option.id}
                control={(
                  <Radio
                    data-testid={`radio-${option.id}`}
                    checked={answers.includes(option.id)}
                  />
                )}
                label={option.internalValue}
                onChange={() => onChange(inputField.id, option.id)}
              />
            ))}
          </RadioGroup>
          <Box maxWidth='250px' mt={1}><Divider /></Box>
        </FormControl>
      ))}
    </Stack>
  )
}
