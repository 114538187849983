import validation from '../../../../../validation'

const getListParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
}

const getParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
  lawId: validation.rules.string().required().label('Law Id'),
}

const getListParams = {
  rules: getListParamRules,
  schema: validation.schema(getListParamRules),
}

const getParams = {
  rules: getParamRules,
  schema: validation.schema(getParamRules),
}

// eslint-disable-next-line import/prefer-default-export
export const get = { params: getParams }

export const getList = { params: getListParams }
