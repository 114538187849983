import lang from '../../../lang'

import { StringSchema } from '../..'

export default function email(this: StringSchema, { isOrganizationEmail }: { isOrganizationEmail?: boolean } = { isOrganizationEmail: false }) {
  /* eslint-disable */
  return this.matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    lang().validation.email(isOrganizationEmail),
  )
  /* eslint-enable */
}
