import * as Yup from 'yup'

import base64Image from './base64Image'
import {
  date,
  datetime,
  time,
} from './date'
import password from './password'
import phoneNumber from './phoneNumber'
import zipCode from './zipCode'
import email from './email'
import integer from './integer'
import url from './url'
import sanitizeForHTMLJS from './sanitizeForHTMLJS'

// Add custom rules
Yup.addMethod(Yup.string, 'base64Image', base64Image)
Yup.addMethod(Yup.string, 'date', date)
Yup.addMethod(Yup.string, 'datetime', datetime)
Yup.addMethod(Yup.string, 'time', time)
Yup.addMethod(Yup.string, 'password', password)
Yup.addMethod(Yup.string, 'phoneNumber', phoneNumber)
Yup.addMethod(Yup.string, 'zipCode', zipCode)
Yup.addMethod(Yup.string, 'email', email)
Yup.addMethod(Yup.string, 'integer', integer)
Yup.addMethod(Yup.string, 'url', url)
Yup.addMethod(Yup.string, 'sanitizeForHTMLJS', sanitizeForHTMLJS)

export default Yup

declare module 'yup' {
  interface StringSchema {
    base64Image(): StringSchema,
    date(): StringSchema,
    datetime(): StringSchema,
    time(): StringSchema,
    password(): StringSchema,
    phoneNumber(options?: { allowInternational?: boolean }): StringSchema,
    zipCode(): StringSchema,
    integer(): StringSchema,
    email(): StringSchema,
    sanitizeForHTMLJS(): StringSchema,
  }
}
