import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import {
  persistReducer,
  persistStore,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import {
  createStateSyncMiddleware,
  withReduxStateSync,
} from 'redux-state-sync'
import storage from 'redux-persist/lib/storage'

import config from '@/config'

import Sentry from '../utils/sentry'

import commonSenseApi from './apis/commonSense'

import sessionReducer from './slices/session'

import productInformationReducer from './slices/productInformation'

import wizardReducer from './slices/wizard'

import { RootState } from './types'

export const rootReducer = withReduxStateSync(combineReducers({
  session: sessionReducer,
  productInformation: productInformationReducer,
  wizard: wizardReducer,
  [commonSenseApi.reducerPath]: commonSenseApi.reducer,
}))

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    'session',
    'wizard',
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const ignoredActions = [
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
]
const middlewares = ([
  commonSenseApi.middleware,
  createStateSyncMiddleware({ predicate: action => action.type === 'session/setSession' }),
])
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: { ignoredActions } }).concat(middlewares),
  enhancers: [ ...(config.sentryEnabled ? [ Sentry.createReduxEnhancer() ] : []) ],
})

export default store

export const persistor = persistStore(store)

export const makeMockStore = (preloadedState: Partial<RootState>) => configureStore({
  preloadedState,
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(commonSenseApi.middleware),
})
