import { PrivacyIssueRecommendedAction } from '@/services/organization/product/recommendedAction/types'

import { ProductRecommendedAction } from '../recommendedAction/types'

import {
  Law,
  LevelOfImportance,
  ProductPrivacyIssue,
  ProductPrivacyIssueAnswer,
  ScoreStatus,
} from './types'

export const ApplicableLawSentinelValue = 'ALWAYSINCLUDE'

export const isAnsweredWithUnclearPractice = (score: number, maxScore: number, answers: ProductPrivacyIssueAnswer[] | undefined) => score === 0 && maxScore > 0 && !answers?.length

export const isAnsweredWithBadPractice = (score: number, maxScore: number, answers: ProductPrivacyIssueAnswer[] | undefined) => score >= 0 && score < maxScore && maxScore > 0 && answers?.length

export const isAnsweredWithGoodPractice = (score: number, maxScore: number) => score === maxScore

export const getProductPrivacyIssueStatus = (productPrivacyIssue: ProductPrivacyIssue): ScoreStatus => {
  const { maxScore } = productPrivacyIssue.privacyIssue
  const answers = productPrivacyIssue?.answers
  const score = getScore(productPrivacyIssue)

  if (isAnsweredWithGoodPractice(score, maxScore)) {
    return 'good'
  }

  if (isAnsweredWithBadPractice(score, maxScore, answers)) {
    return 'normal'
  }

  return 'bad'
}

export const transformProductPrivacyIssue = (productPrivacyIssue: ProductPrivacyIssue | undefined): {
  id: string,
  code: string,
  isClear: boolean,
  importanceLevel: LevelOfImportance,
  question: string,
  score: number,
  maxScore: number,
  answerStatement: string,
  status: ScoreStatus,
  tags: string[],
  type: string,
  title: string,
  answers: ProductPrivacyIssueAnswer[],
} => {
  if (!productPrivacyIssue) {
    return {
      id: '',
      code: '',
      isClear: false,
      importanceLevel: 'Low',
      question: '',
      score: 0,
      maxScore: 0,
      answerStatement: '',
      status: 'bad',
      tags: [],
      type: '',
      title: '',
      answers: [],
    }
  }

  let score = 0
  let answerStatement = ''

  const tags:string[] = []

  const {
    id,
    privacyIssue: {
      code,
      maxScore,
      importanceLevel,
      question,
      applicableLaws,
      privacyTopic: { title },
      type,
      title: privacyIssueTitle,
    },
    answers,
  } = productPrivacyIssue

  const isClear = !!answers && answers.length > 0
  tags.push(title)
  if (applicableLaws) {
    applicableLaws.forEach((law: Law) => {
      law.abbrev !== ApplicableLawSentinelValue && tags.push(law.abbrev)
    })
  }
  tags.sort((a, b) => a.localeCompare(b))

  if (isClear) {
    answers.forEach((answer: ProductPrivacyIssueAnswer) => {
      score += answer.answer?.points || 0
      answerStatement = `${answer.answer?.statement}`
    })
  }

  if (isClear && productPrivacyIssue.productRecommendedActions) {
    const recommendedActionScore = productPrivacyIssue.productRecommendedActions.reduce((
      acc: number,
      productRecommendedAction: ProductRecommendedAction,
    ) => {
      const {
        answers,
        recommendedAction: { privacyIssueRecommendedActions },
      } = productRecommendedAction
      const privacyIssueRecommendedAction = privacyIssueRecommendedActions
        && privacyIssueRecommendedActions.find((privacyIssueRecommendedAction: PrivacyIssueRecommendedAction) => privacyIssueRecommendedAction.privacyIssue.id === productPrivacyIssue.privacyIssue.id)
      if (privacyIssueRecommendedAction && answers?.length) {
        acc += answers.reduce((acc2: number, answer) => {
          acc2 += answer?.answer?.isBetterPractice ? privacyIssueRecommendedAction?.points || 0 : 0

          return acc2
        }, 0)
      }

      return acc
    }, 0)
    score += recommendedActionScore
  }

  return {
    id,
    code,
    isClear,
    importanceLevel: importanceLevel as LevelOfImportance,
    question,
    score,
    maxScore,
    answerStatement,
    status: getProductPrivacyIssueStatus(productPrivacyIssue),
    tags,
    title: privacyIssueTitle,
    type,
    answers: answers || [],
  }
}

export const getScore = (productPrivacyIssue: ProductPrivacyIssue | undefined) => productPrivacyIssue?.answers?.reduce((
  acc: number,
  issue: ProductPrivacyIssueAnswer,
) => acc + (issue.answer?.points || 0), 0) || 0
