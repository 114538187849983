import React, {
  useCallback,
  useContext,
} from 'react'

import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Link,
  Container,
  // Typography,
  // Dialog,
  // List,
  // ListItem,
  // DialogTitle,
  // DialogContent,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
// import CloseIcon from '@mui/icons-material/Close'
// import NotificationsIcon from '@mui/icons-material/Notifications'

import { Link as RouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import useNavigate from '@/hooks/useNavigate'
import useSession, { useAuthInfo } from '@/hooks/useSession'
import { setSession } from '@/state/slices/session'
import { usePostLogoutMutation } from '@/services/auth'

import Logo from '@/components/Logo'
import LogoWizard from '@/components/Logo/Wizard'
import LogoDashboard from '@/components/Logo/Dashboard'

import Menu, { MenuContext } from './Menu'

import { Props } from './types'

function Header({ header = 'default' }: Props): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ postLogout ] = usePostLogoutMutation()
  const session = useSession()
  const {
    isLoggedInAndVerified,
    isNotLoggedIn,
  } = useAuthInfo()

  const { toggleMenu } = useContext(MenuContext)
  // const [
  //   open,
  //   setOpen,
  // ] = React.useState(false)
  // const handleAlertClickOpen = () => {
  //   setOpen(true)
  // }
  // const handleAlertClose = () => {
  //   setOpen(false)
  // }

  const handleLogout = useCallback(async () => {
    postLogout(undefined).finally(() => {
      dispatch(setSession())
      navigate('/')
    })
  }, [
    dispatch,
    postLogout,
    navigate,
  ])

  return (
    <>
      {/* Dsabled for the v1 launch */}
      {/* <Dialog
        onClose={handleAlertClose}
        open={open}
        PaperProps={{
          sx: {
            position: 'absolute',
            top: '30px',
            right: 0,
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
          }}
        >
          Alerts
          <IconButton
            aria-label='close'
            onClick={handleAlertClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem><Typography>Alert List Item</Typography></ListItem>
          <ListItem><Typography>Alert List Item</Typography></ListItem>
          <ListItem><Typography>Alert List Item</Typography></ListItem>
          <ListItem><Typography>Alert List Item</Typography></ListItem>
          <ListItem><Typography>Alert List Item</Typography></ListItem>
          <DialogContent dividers={true}>
            <Typography gutterBottom={true}>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
              dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
              consectetur ac, vestibulum at eros.
            </Typography>
            <Typography gutterBottom={true}>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
              Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
            </Typography>
            <Typography gutterBottom={true}>
              Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
              magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
              ullamcorper nulla non metus auctor fringilla.
            </Typography>
          </DialogContent>
        </List>
      </Dialog> */}
      <Box
        position='fixed'
        top='0'
        width='100%'
        zIndex='3'
        sx={{
          borderBottom: '5px solid #D9D9D9',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Stack direction='row' bgcolor='primary.main' py='2px' minHeight='35px' alignItems='center'>
          <Container>
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              sx={{
                justifyContent: {
                  xs: 'center',
                  md: 'flex-end',
                },
              }}
            >
              {isLoggedInAndVerified && (
                <Stack direction='row' alignItems='center'>
                  <Link
                    to='/account'
                    component={RouterLink}
                    variant='body1'
                    sx={{
                      color: 'white',
                      textDecoration: 'underline',
                      '&:hover': {
                        textDecoration: 'none',
                        cursor: 'pointer',
                      },
                    }}
                  >Hi, { `${session.user?.firstName}` }
                  </Link>
                  {/* Disabled for v1 launch */}
                  {/* <IconButton onClick={handleAlertClickOpen} sx={{ padding: 0 }}>
                    <Box position='relative' height='35px'>
                      <NotificationsIcon fontSize='large' sx={{ color: 'white' }} />
                      <Box sx={{
                        borderRadius: '4px',
                        border: 1,
                        borderColor: 'primary.main',
                        position: 'absolute',
                        top: '7px',
                        right: '2px',
                        padding: '1px',
                        backgroundColor: 'white',
                      }}
                      >
                        <Typography color='primary' lineHeight='1em' fontSize='12px'>9+</Typography>
                      </Box>
                    </Box>
                  </IconButton> */}
                  <Box />
                  <Button
                    variant='text'
                    sx={{
                      color: '#fff',
                      padding: '0 0 0 8px',
                      marginLeft: 1,
                      width: 'auto',
                      fontSize: '16px',
                      borderLeft: '1px solid #fff',
                      borderRadius: 0,
                      height: '24px',
                    }}
                    onClick={handleLogout}
                    role='button'
                  >
                    Sign Out
                  </Button>
                </Stack>
              )}
              { isNotLoggedIn && (
                <Stack
                  divider={<Divider orientation='vertical' flexItem={true} color='white' />}
                  direction='row'
                  spacing={1}
                >
                  <Link
                    to='/signin'
                    component={RouterLink}
                    variant='body1'
                    sx={{
                      color: 'white',
                      textDecoration: 'underline',
                      '&:hover': {
                        textDecoration: 'none',
                        cursor: 'pointer',
                      },
                    }}
                  >Sign In
                  </Link>
                  <Link
                    to='/signup'
                    component={RouterLink}
                    variant='body1'
                    sx={{
                      color: 'white',
                      textDecoration: 'underline',
                      '&:hover': {
                        textDecoration: 'none',
                        cursor: 'pointer',
                      },
                    }}
                  >Sign Up
                  </Link>
                </Stack>
              )}
            </Stack>
          </Container>
        </Stack>
        <Box zIndex='2'>
          <Container>
            <Stack direction='row' alignItems='center'>
              {isLoggedInAndVerified ? (
                <Box>
                  <IconButton onClick={toggleMenu} sx={{ padding: 0 }}>
                    <MenuIcon fontSize='large' />
                  </IconButton>
                </Box>
              ) : undefined}

              <Box
                display='flex'
                justifyContent='center'
                width='100%'
                alignItems='center'
                sx={{ height: 103 }}
              >
                <Box ml={-2}>
                  {header === 'wizard'
                    && (
                      <Link component={RouterLink} to='/'>
                        <LogoWizard />
                      </Link>
                    )}
                  {header === 'dashboard'
                  && (
                    <Link component={RouterLink} to='/dashboard'>
                      <LogoDashboard />
                    </Link>
                  )}
                  {(header === 'default')
                  && (
                    <Logo />
                  )}
                </Box>
              </Box>
            </Stack>
          </Container>
        </Box>
        { isLoggedInAndVerified ? (<Menu />) : undefined}
      </Box>
    </>
  )
}

export default React.memo(Header)
