import validation from '../../../../validation'
import rules from '../../../../validation/rules'

const postBodyRules = { couponCode: rules.string().required().label('Coupon Code') }

const postBody = {
  rules: postBodyRules,
  schema: validation.schema(postBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const post = { body: postBody }
