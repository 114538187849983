import {
  createTheme,
  ThemeOptions,
} from '@mui/material'
import '@fontsource/lato/300.css'
import '@fontsource/lato/400.css'
import '@fontsource/lato/700.css'
import '@fontsource/lato/900.css'
import '@fontsource/oswald/400.css'

declare module '@mui/material/styles' {

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    secondary?: React.CSSProperties,
    body3?: React.CSSProperties,
    intro?: React.CSSProperties,
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    secondary: true,
    body3: true,
    intro: true,
  }
}

declare module '@mui/material/styles' {
  // allow configuration using `createTheme`
  interface ButtonVariantsOptions {
    cancel?: React.CSSProperties,
    back?: React.CSSProperties,
    link?: React.CSSProperties,
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    cancel: true,
    back: true,
    link: true,
  }
}

declare module '@mui/material/FormControl' {
  interface FormControlPropsVariantOverrides {
    outlined: true,
  }
}

declare module '@mui/material/styles' {
  // allow configuration using `createTheme`
  interface ChipVariantsOptions {
    results?: React.CSSProperties,
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    results: true,
  }
}
declare module '@mui/material/styles' {
  interface PaletteColor {
    background?: string,
  }

  interface SimplePaletteColorOptions {
    background?: string,
  }
}
const typography: ThemeOptions['typography'] = {
  fontFamily: [
    'Lato',
    'sans-serif',
  ].join(','),
  h1: {
    fontWeight: 400,
    fontSize: 28,
    textTransform: 'uppercase',
    fontFamily: [
      'Oswald',
      'sans-serif',
    ].join(','),
  },
  h2: {
    fontWeight: 400,
    fontSize: 24,
    textTransform: 'uppercase',
    fontFamily: [
      'Oswald',
      'sans-serif',
    ].join(','),
  },
  h3: {
    fontWeight: 700,
    fontSize: 20,
    textTransform: 'uppercase',
    fontFamily: [
      'Oswald',
      'sans-serif',
    ].join(','),
  },
  h4: {
    fontWeight: 900,
    fontSize: 24,
  },
  h5: { fontWeight: 700 },
  h6: {
    lineHeight: 1.3,
    fontSize: 20,
    fontWeight: 700,
  },
  secondary: {
    fontFamily: [
      'Oswald',
      'sans-serif',
    ].join(','),
  },
  subtitle1: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontFamily: [
      'Oswald',
      'sans-serif',
    ].join(','),
  },
  subtitle2: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontFamily: [
      'Oswald',
      'sans-serif',
    ].join(','),
  },
  body2: {
    color: '#000000',
    fontSize: 14,
  },
}
// Customize theme for just the web app
const webTheme: ThemeOptions = {
  typography,
  components: {
    // Name of the component
    MuiFormLabel: { styleOverrides: { root: { color: '#000' } } },
    MuiCardContent: { styleOverrides: { root: { padding: '16px' } } },
    MuiCard: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: { borderRadius: '20px' },
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          p: 2,
          m: 0,
          color: '#197FA2',
          fontWeight: 'bold',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          color: '#3A3A3A',
          backgroundColor: '#FFFFFF',
          border: '1px solid #3A3A3A',
        },
      },
    },
    MuiTextField: { defaultProps: { InputLabelProps: { shrink: true } } },
    MuiOutlinedInput: { defaultProps: { notched: true } },
    MuiSelect: { defaultProps: { notched: true } },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
        },
      },
    },
    MuiInputLabel: { defaultProps: { shrink: true } },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Lato',
            'sans-serif',
          ].join(','),
          paddingLeft: 0,
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'body3' },
          style: {
            fontSize: '.75rem',
            lineHeight: 1.3,
            fontFamily: [
              'Lato',
              'sans-serif',
            ].join(','),
          },
        },
        {
          props: { variant: 'intro' },
          style: {
            fontSize: 20,
            lineHeight: 1.3,
            display: 'block',
            fontFamily: [
              'Lato',
              'sans-serif',
            ].join(','),
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            fontFamily: [
              'Oswald',
              'sans-serif',
            ].join(','),
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'results' },
          style: { backgroundColor: 'rgba(37, 181, 232, 0.15)' },
        },
      ],
    },
    MuiButton: {
      defaultProps: { variant: 'contained' },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '20px',
          textTransform: 'none',
          fontWeight: 'bold',
          lineHeight: 1.4,
          paddingTop: '8px',
          paddingBottom: '8px',
        },
      },
      variants: [
        {
          props: { variant: 'cancel' },
          style: {
            backgroundColor: '#333333',
            color: '#fff',
            '&:hover': { background: '#666666' },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: '#FFF',
            '&:hover': { background: '#FFF' },
          },
        },
        {
          props: { variant: 'back' },
          style: {
            backgroundColor: '#33A544',
            color: '#fff',
            width: 'auto',
            paddingLeft: 22,
            '&:hover': { background: 'rgb(35, 115, 47)' },
            '&::before': {
              content: '"<"',
              display: 'block',
              position: 'absolute',
              color: '#fff',
              left: 10,
              top: 5,
            },
          },
        },
        {
          props: { variant: 'link' },
          style: {
            backgroundColor: 'transparent',
            color: '#33A544',
            width: 'auto',
            padding: 0,
            justifyContent: 'flex-start',
            '&:hover': {
              background: 'transparent',
              textDecoration: 'underline',
            },
          },
        },
      ],
    },
    // this is a workaround to make the form control work with styles that we used for resource detail
    MuiFormControl: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: { ...typography },
        },
      ],
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#33A544',
      dark: '#247330',
      background: 'rgba(51, 165, 68, 0.1)',
    },
    secondary: {
      light: '#A9E6FB',
      main: '#23B5E8',
      dark: '#197FA2',
    },
    text: {
      primary: '#3A3A3A',
      secondary: '#999999',
    },
  },
}
const theme = createTheme(webTheme)

export default theme
