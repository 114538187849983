import { useSelector } from 'react-redux'

import { getPlanType } from '@/utils/stripe'

import {
  sessionSelectors,
  Organization,
  UserOrganization,
  Role,
  UserOrganizationRole,
  Product,
  SubscriptionWithExpansions,
} from '../../state/slices/session'

function useSession() {
  const session = useSelector(sessionSelectors.session)

  return session || {}
}

export function useUserOrganization(): UserOrganization {
  const session = useSession()
  const organizations = session.user?.organizations ?? []

  const [ userOrganization ] = organizations

  return userOrganization
}

export function useCurrentOrganization(): Organization {
  const userOrganization = useUserOrganization()

  return userOrganization?.organization || {
    id: '',
    name: '',
    size: '',
    city: '',
    email: '',
    streetAddress: '',
    zipCode: '',
    state: '',
    poBox: '',
    hasProductPolicies: 0,
  }
}

export function useCurrentProduct(): Product {
  const currentOrganization = useCurrentOrganization()

  const products = currentOrganization?.products || []

  // application supports only one product. it's safe to pull the first product from products array
  return products.at(0) || {} as Product
}

export function useCurrentRoleNames() {
  const userOrganization = useUserOrganization()
  const roles: Role[] = userOrganization?.roles.map((organizationRole: UserOrganizationRole) => organizationRole.role) || []

  return roles.map(role => role.name)
    .sort((a, b) => a.localeCompare(b))
    .join(', ')
}

export function useUserEmail() {
  const session = useSession()

  return session.user?.email || ''
}

export function useAuthInfo() {
  const session = useSession()
  const fullName = `${session.user?.firstName} ${session.user?.lastName}`
  const isLoggedInAndVerified = !!session.user && !!session.user.emailVerifiedAt
  const isNotLoggedIn = !session.user

  return {
    fullName,
    isLoggedInAndVerified,
    isNotLoggedIn,
  }
}

export function useOrganizationSubscription(): SubscriptionWithExpansions | undefined {
  const session = useSession()

  return session?.subscription || undefined
}

export function usePlanType() {
  const subscription = useOrganizationSubscription()

  return subscription ? getPlanType(subscription) : undefined
}

export function useDefaultPaymentMethod() {
  const subscription = useOrganizationSubscription()

  return subscription?.customer?.invoice_settings?.default_payment_method
}

export function useCurrentSubscriptionPlan() {
  const subscription = useOrganizationSubscription()

  return subscription?.plan
}

export default useSession

export * from '../../state/slices/session'
