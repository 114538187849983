import React from 'react'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from '@mui/material'

import {
  Props, PrivacyIssueAnswerInputField,
} from './types'

export default function RadioGroupList({
  inputFields,
  answers,
  name,
  onChange,
}: Props) {
  return (
    <>
      {inputFields.map((inputField: PrivacyIssueAnswerInputField) => (
        <FormControl key={`${inputField.id}-input-field`} sx={{ marginTop: 0 }}>
          <RadioGroup name={name}>
            {inputField.privacyIssueAnswerInputFieldOptions.map(option => (
              <FormControlLabel
                key={option.id}
                value={option.id}
                control={(
                  <Radio
                    data-testid={`radio-${option.id}`}
                    checked={answers.includes(option.id)}
                  />
                )}
                label={option.value}
                onChange={(event: React.SyntheticEvent) => onChange(
                  option.id,
                  (event.target as HTMLInputElement).checked,
                )}
              />
            ))}
          </RadioGroup>
        </FormControl>
      ))}
    </>
  )
}
