import validation from '../../../validation'
import rules from '../../../validation/rules'

const postBodyRules = { token: rules.string().required() }

const postBody = {
  rules: postBodyRules,
  schema: validation.schema(postBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const post = { body: postBody }
