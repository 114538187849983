export const enum Color {
  GREEN='green',
  BLUE='blue',
  ORANGE='orange'
}

export type Props = {
  color?: Color,
  value: number,
  hasLabel?: boolean,
  width?: {
    xs?: string,
    sm?: string,
    md?: string,
    lg?: string,
    xl?: string,
  },
}
