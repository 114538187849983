import validation from '../../../../../../validation'

export type FileTypes = 'policyPdf' | 'googlePrivacyLabelCsv'

const getParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
  snapshotId: validation.rules.string().required().label('Snapshot ID'),
  fileType: validation.rules.string().oneOf([
    'policyPdf',
    'googlePrivacyLabelCsv',
  ]).required().label('File Type'),
}

const getParams = {
  rules: getParamRules,
  schema: validation.schema(getParamRules),
}

export const get = { params: getParams }
