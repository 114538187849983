import lang from '../../../lang'

import { StringSchema } from '../..'

export const minimumLength = 8

export default function password(this: StringSchema) {
  return this
    .matches(/\w*[a-z]\w*/, lang().validation.passwordLowerCaseLetterRequired())
    .matches(/\d/, lang().validation.passwordNumerRequired())
    .matches(/[!+@#$%^&*()\-_"=+{}; :,<.>]/, lang().validation.passwordSpecialCharacterRequired())
    .min(minimumLength, ({ min }) => lang().validation.passwordMinimumLength(min))
}
