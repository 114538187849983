function get<T extends string | number | boolean>(key: string, type: 'bool' | 'number' | 'string' = 'string', dflt: unknown = ''): T {
  const value = process.env[key] || process.env[`REACT_APP_${key}`]

  if (type === 'bool') {
    if (value === 'true') {
      return true as T
    }

    if (value === 'false') {
      return false as T
    }

    return !!dflt as T
  }

  if (type === 'number') {
    return Number(value) as T
  }

  if (type === 'string') {
    return (value || dflt) as T
  }

  return value as T
}

export default { get }
