import lang from '../../../lang'

import { StringSchema } from '../..'

export const minimumLength = 8

export function date(this: StringSchema) {
  return this.matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, lang().validation.invalidDate())
}

export function datetime(this: StringSchema) {
  return this.matches(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9](\.?\d*)$/, lang().validation.invalidDatetime())
}

export function time(this: StringSchema) {
  return this.matches(/^(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9](\.?\d*)$/, lang().validation.invalidTime())
}
