import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import {
  dateTime,
  lang,
} from '@common-sense-privacy/common'

import CircularLoading from '@/components/CircularLoading'
import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import { useAlert } from '@/context/AlertContext'

import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'

import {
  useGetProductSnapshotListQuery,
  useDownloadProductSnapshotMutation,
  usePostProductSnapshotMutation,
} from '@/services/organization/product/snapshot'

import type {
  ListProps,
  PrivacyPolicyProps,
} from './types'

function PrivacyPolicyList(): React.ReactElement {
  const { id: organizationId } = useCurrentOrganization()
  const { id: productId } = useCurrentProduct() ?? { id: '' }

  const { setAlert } = useAlert()

  const {
    data: { data: snapshots } = { data: [] },
    isLoading,
  } = useGetProductSnapshotListQuery(
    {
      params: {
        id: organizationId,
        productId,
      },
      query: { retryIfIncomplete: 'true' },
    },
    {
      pollingInterval: 5000,
      refetchOnMountOrArgChange: true,
    },
  )

  const [
    currentSnapshot,
    ...previousSnapshots
  ] = useMemo(() => snapshots || [], [ snapshots ])

  const [
    postProductSnapshot,
    { isLoading: isLoadingUpdatePrivacyPolicy },
  ] = usePostProductSnapshotMutation()

  const onClickUpdatePrivacyPolicy = useCallback(async () => {
    if (!productId) {
      setAlert({
        description: lang().messages.unknownError(),
        type: 'error',
      })

      return
    }

    await postProductSnapshot({
      params: {
        id: organizationId,
        productId,
      },
    })
      .unwrap()
      .catch(() => {
        setAlert({
          description: lang().messages.somethingWentWrong(),
          type: 'error',
        })
      })
  }, [
    organizationId,
    postProductSnapshot,
    productId,
    setAlert,
  ])

  return (
    <DocumentWrapper title='Common Sense Privacy | Privacy Policy'>
      <ScreenWrapper>
        {(isLoading && <CircularLoading />) || (
          <Container>
            <Stack spacing={4} alignItems='flex-start'>
              <Box>
                <Typography variant='h1'>Privacy Policies</Typography>
                <Typography>Privacy policies generated using the Common Sense Privacy Wizard appear below.</Typography>
              </Box>
              <Alert severity='info'>
                Common Sense Privacy is not a law firm and is not providing partner legal advice or representation. Run suggested privacy policies past your attorney before putting them into use.
              </Alert>

              {(currentSnapshot?.isOutOfDate || currentSnapshot?.status.match(/^failed\./)) && (
                <Button
                  disabled={isLoadingUpdatePrivacyPolicy}
                  onClick={onClickUpdatePrivacyPolicy}
                  variant='outlined'
                >
                  Update Privacy Policy
                </Button>
              )}
            </Stack>

            <List
              organizationId={organizationId}
              productId={productId}
              showIsOutOfDate={true}
              snapshots={currentSnapshot ? [ currentSnapshot ] : []}
              title='Current Version'
            />

            {currentSnapshot && (
              <List
                organizationId={organizationId}
                productId={productId}
                snapshots={previousSnapshots}
                title='Previous Versions'
              />
            )}
          </Container>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

function List({
  organizationId,
  productId,
  showIsOutOfDate = false,
  snapshots = [],
  title,
}: ListProps): React.ReactElement {
  return (
    <Stack spacing={4} mt={6}>
      <Stack spacing={1}>
        <Typography variant='h3'>{title}</Typography>
        <Divider />
      </Stack>

      {(!snapshots.length && <Typography>No privacy policies to show.</Typography>) || (
        <>
          {snapshots.map((snapshot, i) => (
            <React.Fragment key={snapshot.id}>
              <PrivacyPolicy
                organizationId={organizationId}
                productId={productId}
                showIsOutOfDate={showIsOutOfDate}
                snapshot={snapshot}
              />

              {i < snapshots.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </>
      )}
    </Stack>
  )
}

function PrivacyPolicy({
  organizationId,
  productId,
  showIsOutOfDate,
  snapshot,
}: PrivacyPolicyProps): React.ReactElement {
  const { setAlert } = useAlert()

  const [
    downloadProductSnapshot,
    { isLoading: isDownloading },
  ] = useDownloadProductSnapshotMutation()

  const handleOnDownload = async (snapshotId: string, fileType: 'policyPdf' | 'googlePrivacyLabelCsv' = 'policyPdf') => {
    downloadProductSnapshot({
      params: {
        id: organizationId,
        productId: `${productId}`,
        snapshotId: `${snapshotId}`,
        fileType,
      },
    })
      .unwrap()
      .catch(() => {
        setAlert({
          description: lang().messages.somethingWentWrong(),
          type: 'error',
        })
      })
  }

  const isComplete = useMemo(() => snapshot.status === 'complete', [ snapshot.status ])
  const isGenerating = useMemo(() => snapshot.status === 'new' || snapshot.status.match(/^generating\./), [ snapshot.status ])
  const shouldHavePrivacyLabel = snapshot.shouldHavePrivacyPolicyCsv

  return (
    <Stack
      direction={{
        xs: 'column',
        md: 'row',
      }}
      justifyContent='space-between'
      alignItems={{ md: 'center' }}
    >
      <Stack>
        <Typography fontSize={13}>{dateTime.format(snapshot.createdAt, 'MM.dd.yyyy | hh:mm aaa')}</Typography>
        <Typography fontWeight='bold' fontSize={24}>Privacy Policy</Typography>
        {showIsOutOfDate && snapshot.isOutOfDate && (
          <Stack direction='row'>
            <WarningAmberIcon
              aria-label='Your data has changed since this policy was generated.'
              color='warning'
            />
            <Typography ml={1} fontSize={14} color='warning.main'>Your data has changed since this policy was generated.</Typography>
          </Stack>
        )}
      </Stack>
      <Stack
        direction='row'
        spacing={3}
      >
        {(isGenerating && (
          <CircularProgress
            size='2rem'
          />
        )) || (isComplete && (
          <Button
            variant='link'
            disabled={isDownloading}
            onClick={() => handleOnDownload(snapshot.id)}
            role='button'
            sx={{ width: 'auto' }}
          >
            Download Privacy Policy PDF
          </Button>
        )) || (
          <ErrorIcon
            aria-label='Unable to generate PDF'
            color='error'
            fontSize='large'
          />
        )}

        {(isGenerating && (
          <CircularProgress
            size='2rem'
          />
        )) || ((isComplete && snapshot.googlePrivacyLabelCsvLink) && (
          <Stack direction='row' alignItems='center'>
            <Button
              variant='link'
              disabled={isDownloading}
              onClick={() => handleOnDownload(snapshot.id, 'googlePrivacyLabelCsv')}
              sx={{
                width: 'auto',
                mr: 0.5,
              }}
            >
              Google Play Store Privacy Label
            </Button>
            <Tooltip
              placement='top'
              enterTouchDelay={0}
              PopperProps={{
                disablePortal: true,
                sx: {
                  '& .MuiTooltip-tooltip': {
                    height: '100%',
                    maxHeight: '150px',
                    overflow: 'auto',
                  },
                },
              }}
              title={(
                <Typography variant='body2'>
                  Learn about Google Play Store privacy labels{' '}
                  <Button
                    variant='link'
                    onClick={() => {
                      window.open('https://support.google.com/googleplay/android-developer/answer/10787469?hl=en', '_blank')
                    }}
                    rel='noreferrer'
                  >here
                  </Button>.
                </Typography>
              )}
            ><InfoOutlinedIcon htmlColor='grey' />
            </Tooltip>
          </Stack>
        ))}
        {(shouldHavePrivacyLabel && !snapshot.googlePrivacyLabelCsvLink && isComplete) && (
          <ErrorIcon
            aria-label='Unable to generate label'
            color='error'
            fontSize='large'
          />
        )}
      </Stack>
    </Stack>
  )
}

export default React.memo(PrivacyPolicyList)
