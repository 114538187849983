import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import ResourceCard from '@/components/ResourceCard'

import { useGetArticlesQuery } from '@/services/article'

import Loading from '@/screens/Wizard/components/Loading'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

function DashboardResources(): React.ReactElement {
  const [
    offset,
    setOffset,
  ] = useState(0)
  const itemsPerPage = 10
  const {
    data: articles,
    isLoading,
  } = useGetArticlesQuery({
    query: {
      offset: `${offset || 0}`,
      limit: `${itemsPerPage || 20}`,
    },
  }, { refetchOnMountOrArgChange: true })

  const handleShowMore = () => {
    setOffset(offset + itemsPerPage)
  }

  return (
    <DocumentWrapper title='Common Sense Privacy | Dashboard Resources'>
      <ScreenWrapper bgcolor='grey.50'>
        {isLoading && (<Loading />)}
        {!isLoading && (
          <>
            <Container>
              <Stack direction='row' justifyContent='space-between' mb={6}>
                <Typography variant='h1'>Resources</Typography>
              </Stack>
            </Container>
            <Container>
              <Grid container={true} spacing={8} mb={6}>
                {articles?.data?.items?.map(article => (
                  <Grid key={article.id} item={true} xs={12} sm={6} md={4}>
                    <ResourceCard
                      article={article}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box mb={6} textAlign='center' position='relative'>
                <Box position='absolute' width='100%' top='50%'>
                  <Divider />
                </Box>
                <Button
                  disabled={articles?.data?.items?.length === articles?.data?.total}
                  variant='outlined'
                  onClick={handleShowMore}
                >More Resources
                </Button>
              </Box>
            </Container>
          </>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DashboardResources)
