import React, {
  useEffect, useState,
} from 'react'
import {
  Box, TextField,
} from '@mui/material'

import { Props } from './types'

export default function FreeTextInput({
  answer,
  onChange,
}: Props) {
  const [
    value,
    setValue,
  ] = useState('')

  useEffect(() => {
    setValue(answer || '')
  }, [ answer ])

  return (
    <Box maxWidth='md'>
      <TextField
        name='FreeText'
        fullWidth={true}
        label='Free Text'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value)
        }}
        value={value}
      />
    </Box>
  )
}
