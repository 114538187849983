import commonSenseApi from '@/state/apis/commonSense'

import { objectToQueryString } from '@/utils/queryString'

import {
  DownloadProductSnapshotRequest,
  GetProductSnapshotRequest,
  GetProductSnapshotResponseBody,
  GetProductSnapshotListRequest,
  GetProductSnapshotListResponseBody,
  PostProductSnapshotRequest,
  PostProductSnapshotResponseBody,
} from './types'

const productSnapshotService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    downloadProductSnapshot: build.mutation<unknown, DownloadProductSnapshotRequest>({
      query: ({
        params: {
          id,
          productId,
          snapshotId,
          fileType,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/snapshots/${snapshotId}/download/${fileType}`,
        method: 'POST',
        responseHandler: async response => {
          function download(blob: Blob, filename: string) {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = filename
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          }
          const blob = await response.blob()
          if (fileType === 'policyPdf') {
            download(blob, `${snapshotId}.pdf`)
          }
          else {
            download(blob, `${snapshotId}.csv`)
          }
        },
        cache: 'no-cache',
      }),
    }),
    getProductSnapshot: build.query<GetProductSnapshotResponseBody, GetProductSnapshotRequest>({
      query: ({
        params: {
          id,
          productId,
          snapshotId,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/snapshots/${snapshotId}`,
        method: 'GET',
      }),
    }),
    getProductSnapshotList: build.query<GetProductSnapshotListResponseBody, GetProductSnapshotListRequest>({
      query: ({
        params: {
          id,
          productId,
        },
        query = {},
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/snapshots?${objectToQueryString(query)}`,
        method: 'GET',
      }),
    }),
    postProductSnapshot: build.mutation<PostProductSnapshotResponseBody, PostProductSnapshotRequest>({
      query: ({
        params: {
          id,
          productId,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/snapshots`,
        method: 'POST',
      }),
    }),
  }),
})

export default productSnapshotService

export const {
  useDownloadProductSnapshotMutation,
  useGetProductSnapshotQuery,
  useGetProductSnapshotListQuery,
  usePostProductSnapshotMutation,
} = productSnapshotService

export const {
  endpoints: {
    downloadProductSnapshot,
    getProductSnapshot,
    getProductSnapshotList,
    postProductSnapshot,
  },
} = productSnapshotService

export * from './types'
