"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function sanitizeForHTMLJS() {
    /* eslint-disable */
    return this.test('htmlJsSanitization', 'Please don\'t include HTML or JS code.', (value) => {
        const combinedPattern = new RegExp('<[^>]*>|<script[^>]*>[\\s\\S]*?<\\/script>', 'i'); // Combined pattern to guard against HTML and JS code
        if (value && combinedPattern.test(value)) {
            return false;
        }
        return true;
    });
    /* eslint-enable */
}
exports.default = sanitizeForHTMLJS;
