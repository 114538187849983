import dateTime from '@/utils/dateTime'

import {
  Plan,
  Subscription,
  TransformPlansProps,
  TransformPlanProps,
  PlanType,
} from './types'

const planTypeTier: Record<string, number> = {
  [PlanType.Starter]: 0,
  [PlanType.Wizard]: 1,
  [PlanType.Dashboard]: 2,
}

export const getSubscriptionPrice = (subscription: Subscription | undefined) => subscription?.items.data[0].price.unit_amount

export const getSubscriptionPriceInfo = (subscription: Subscription) => {
  const priceId = subscription.plan.id
  const priceAmount = (subscription.plan.amount || 0)
  const price = priceAmount !== 0 ? `$${Math.round(priceAmount / 100)}` : 'Free'
  const billingInterval = subscription.plan.interval

  return {
    priceId,
    price,
    billingInterval,
    isFree: priceAmount === 0,
  }
}

export const getCustomerIdFromSubscription = (subscription: Subscription) => {
  if (typeof subscription.customer === 'string') {
    return subscription.customer
  }

  return subscription.customer.id
}

export const getSubscriptionExpiration = (subscription: Subscription) => dateTime.format(new Date(subscription.current_period_end * 1000), 'MM.dd.yyyy')

export const subscriptionHasFailedPayments = (subscription: Subscription) => subscription.status === 'past_due' || subscription.status === 'unpaid'

export const getPlanPriceInfo = (plan: Plan, priceIndex = 0) => {
  const priceObject = plan.prices[priceIndex]
  const priceId = priceObject.id
  const priceAmount = priceObject.unit_amount || 0
  const price = priceAmount !== 0 ? `$${Math.round(priceAmount / 100)}` : 'Free'
  const billingInterval = (priceAmount !== 0 ? priceObject.recurring?.interval : '') || ''

  return {
    priceId,
    price,
    billingInterval,
    isFree: priceAmount === 0,
    priceAmount,
  }
}

export const transformPlan = ({
  plan,
  subscription,
}: TransformPlanProps) => {
  const subscribedProduct = subscription?.items.data[0].price.product
  let subscribedProductId
  if (subscribedProduct) {
    subscribedProductId = typeof subscribedProduct === 'string' ? subscribedProduct : subscribedProduct.id
  }
  const planPriceInfo = getPlanPriceInfo(plan)

  return {
    id: plan.id,
    name: plan.name,
    tagline: plan.description,
    ...planPriceInfo,
    features: plan.features.map(feature => feature.name).filter(feature => !!feature) as string[],
    order: plan.metadata.order,
    isActive: subscribedProductId === plan.id,
    scheduledForDowngrade: subscription?.upcomingPlanChangePhase?.items?.[0]?.price === planPriceInfo.priceId,
    downgradeHelperText: subscription?.upcomingPlanChangePhase?.items?.[0]?.price === planPriceInfo.priceId ? ` Scheduled for change on ${dateTime.format(new Date((subscription?.upcomingPlanChangePhase?.start_date || 0) * 1000), 'MM.dd.yyyy')}` : '',
  }
}

export const transformPlans = ({
  plans,
  subscription,
}: TransformPlansProps) => plans.map(plan => transformPlan({
  plan,
  subscription,
})).sort((a, b) => a.order.localeCompare(b.order))

export const sortPlansByOrder = (plans: Plan[]) => plans.sort((a, b) => a.metadata.order.localeCompare(b.metadata.order))

export const getPlanType = (subscription: Subscription) => {
  const plan = subscription.plan.product

  return plan?.metadata?.type
}

export const getPlanName = (subscription: Subscription) => {
  const plan = subscription.plan.product

  return plan.name === 'Starter' ? 'Get Started' : plan.name
}

export const isAtLeastStarterPlan = (planType: string) => (
  planType === PlanType.Starter
  || planType === PlanType.Wizard
  || planType === PlanType.Dashboard
)

export const isAtLeastWizardPlan = (planType: string) => (
  planType === PlanType.Wizard
  || planType === PlanType.Dashboard
)

export const isAtLeastDashboardPlan = (planType: string) => planType === PlanType.Dashboard

export const isStarterPlan = (planType: string) => planType === PlanType.Starter

export const isWizardPlan = (planType: string) => planType === PlanType.Wizard

export const isDashboardPlan = (planType: string) => planType === PlanType.Dashboard

export const isHigherTierPlan = (planTypeA: string, planTypeB: string) => planTypeTier[planTypeA] > planTypeTier[planTypeB]
