import validation from '../../../../../validation'

const getParamRules = {
  id: validation.rules.string().required().label('Organization Id'),
  productId: validation.rules.string().required().label('Product Id'),
}

const getParams = {
  rules: getParamRules,
  schema: validation.schema(getParamRules),
}

// eslint-disable-next-line import/prefer-default-export
export const get = { params: getParams }

const getByIdParamRules = {
  ...getParamRules,
  topicId: validation.rules.string().required().label('Privacy Topic Id'),
}

const getByIdParams = {
  rules: getByIdParamRules,
  schema: validation.schema(getByIdParamRules),
}

// eslint-disable-next-line import/prefer-default-export
export const getById = { params: getByIdParams }
