import validation from '../../../../validation'
import rules from '../../../../validation/rules'

const patchBodyRules = { isEmailNotificationEnabled: rules.boolean().required().label('Email Notification Setting') }

const patchBody = {
  rules: patchBodyRules,
  schema: validation.schema(patchBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const patch = { body: patchBody }
