import commonSenseApi from '../../state/apis/commonSense'

import {
  GetOneRequest,
  GetOneResponseBody,
  GetListResponseBody,
  GetListRequest,
} from './types'

const articleService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getArticles: build.query<GetListResponseBody, GetListRequest>({
      query: ({
        query: {
          offset,
          limit,
        },
      }) => ({
        url: `v1/articles?offset=${offset}&limit=${limit}`,
        method: 'GET',
      }),
      serializeQueryArgs: ({ endpointName }) => `${endpointName}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      merge: (currentCache: any, responseData: any) => {
        const {
          total,
          offset,
          limit,
          items,
        } = responseData.data

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newItems: any[]
        if (offset < currentCache.data?.items.length) {
          newItems = items
        }
        else {
          newItems = (currentCache.data?.items || []).concat(items)
        }

        currentCache.data = {
          total,
          offset,
          limit,
          items: newItems,
        }
      },
    }),
    getArticle: build.query<GetOneResponseBody, GetOneRequest>({
      query: ({ params: { id } }) => ({
        url: `v1/articles/${id}`,
        method: 'GET',
      }),
    }),
    getArticlePreview: build.query<GetOneResponseBody, GetOneRequest>({
      query: ({ params: { id } }) => ({
        url: `v1/articles/${id}/preview`,
        method: 'GET',
      }),
    }),
  }),
})

export default articleService

export const {
  useGetArticlesQuery,
  useGetArticleQuery,
  useGetArticlePreviewQuery,
} = articleService

export const { endpoints: { getArticles } } = articleService
