import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dateTime from '@/utils/dateTime'

import { Props } from './types'

export default function DateInput({
  answers,
  onChange,
}: Props) {
  const value = useMemo(
    () => answers?.[0] && !Number.isNaN(new Date(answers[0]).getTime())
      ? new Date(answers[0])
      : null, // eslint-disable-line no-null/no-null
    [ answers ],
  )

  return (
    <Box maxWidth='sm'>
      <DatePicker<Date>
        onChange={value => {
          onChange(value ? [ dateTime.format(value, 'yyyy/MM/dd') ] : [])
        }}
        value={value}
      />
    </Box>
  )
}
