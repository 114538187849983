import { StringSchema } from '../..'

export default function sanitizeForHTMLJS(this: StringSchema) {
  /* eslint-disable */
  return this.test('htmlJsSanitization', 'Please don\'t include HTML or JS code.', (value: string | undefined) => {
      const combinedPattern = new RegExp('<[^>]*>|<script[^>]*>[\\s\\S]*?<\\/script>', 'i'); // Combined pattern to guard against HTML and JS code
      if(value && combinedPattern.test(value)) {
        return false;
      }
      return true;
    }
  )
  /* eslint-enable */
}
