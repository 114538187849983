import {
  Button,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ResultsProgressBar from '../ResultsProgressBar'
import { Color } from '../ResultsProgressBar/types'

import { Props } from './types'

export default function DescriptiveProgressBar({
  title,
  description,
  progress,
  color,
  linkPath,
  linkText,
}: Props) {
  return (
    <Stack spacing={1} mb={4}>
      {title && (<Typography variant='h2'>{title}</Typography>)}

      {description && (<Typography>{description}</Typography>)}

      <Stack
        spacing={2}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <ResultsProgressBar
          color={color || Color.ORANGE}
          value={progress}
          width={{
            sm: '70%',
            md: '80%',
            lg: '80%',
            xl: '80%',
          }}
        />
        {(linkPath && linkText && color !== Color.GREEN) && <Button to={linkPath} component={RouterLink} variant='outlined'>{linkText}</Button>}
      </Stack>
    </Stack>
  )
}
