import React, { useMemo } from 'react'
import {
  Stack,
  Typography,
  Button,
  Box,
  IconButton,
  Divider,
  useMediaQuery,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import {
  FieldArray,
  FieldArrayRenderProps,
} from 'formik'

import FormField from '@/components/FormField'

import { VendorListValues } from '../../types'

import { VendorListProps } from './types'

export default function VendorList({
  inputFields,
  formik,
}: VendorListProps) {
  const [
    nameField,
    urlField,
    descriptionField,
  ] = useMemo(() => ([
    inputFields.find(inputField => inputField.label.toLowerCase().includes('name')),
    inputFields.find(inputField => inputField.label.toLowerCase().includes('url')),
    inputFields.find(inputField => inputField.label.toLowerCase().includes('description')),
  ]), [ inputFields ])
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const direction = isSmallScreen ? 'column' : 'row'
  const renderVendorField = (vendor: VendorListValues['vendorList'][0] & { index: number }, arrayHelpers: FieldArrayRenderProps) => {
    const { index } = vendor

    return (
      <Stack key={index}>
        {values?.vendorList.length > 1 && (
          <Stack mb={2}>
            <Divider />
            <Typography mt={0.5} variant='body1'>{`Vendor #${index + 1}`}</Typography>
          </Stack>
        )}
        <Stack flexWrap='wrap' direction={direction} spacing={5} flex={9}>
          <Stack flex={3}>
            {isSmallScreen && (
              <>
                <Typography variant='h6' mb={1}>{nameField?.label}</Typography>
                <Typography variant='body2' mb={1}>{nameField?.description}</Typography>
              </>
            )}
            <FormField
              name={`vendorList.${index}.name`}
              autoFocus={true}
            />
          </Stack>
          <Stack flex={3}>
            {isSmallScreen && (
              <>
                <Typography variant='h6' mb={1}>{urlField?.label}</Typography>
                <Typography variant='body2' mb={1}>{urlField?.description}</Typography>
              </>
            )}
            <FormField
              name={`vendorList.${index}.url`}
              fieldHelperText='URL ex: https://domain-name.com'

            />
          </Stack>
          <Stack flex={3}>
            {isSmallScreen && (
              <>
                <Typography variant='h6' mb={1}>{descriptionField?.label}</Typography>
                <Typography variant='body2' mb={1}>{descriptionField?.description}</Typography>
              </>
            )}
            <FormField
              name={`vendorList.${index}.description`}
              autoFocus={true}
            />
          </Stack>
          <Stack flex={1} alignItems={isSmallScreen ? 'center' : 'flex-start'}>
            <Box>
              {index > 0 && (
                <IconButton onClick={() => arrayHelpers.remove(index)}>
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    )
  }
  const { values } = formik

  return (
    <Stack spacing={3} py={6}>
      {values?.vendorList?.length && (
        <FieldArray
          name='vendorList'
          render={arrayHelpers => (
            <>
              {(values?.vendorList[0] && !isSmallScreen) && (
                <Stack flexWrap='wrap' direction={direction} spacing={5} flex={9}>
                  <Stack flex={3}>
                    <Stack>
                      <Typography variant='h6' mb={1}>{nameField?.label}</Typography>
                      <Typography variant='body2' mb={1}>{nameField?.description}</Typography>
                    </Stack>
                  </Stack>
                  <Stack flex={3}>
                    <Stack>
                      <Typography variant='h6' mb={1}>{urlField?.label}</Typography>
                      <Typography variant='body2' mb={1}>{urlField?.description}</Typography>
                    </Stack>
                  </Stack>
                  <Stack flex={3}>
                    <Stack>
                      <Typography variant='h6' mb={1}>{descriptionField?.label}</Typography>
                      <Typography variant='body2' mb={1}>{descriptionField?.description}</Typography>
                    </Stack>
                  </Stack>
                  <Stack flex={1} />
                </Stack>

              )}
              {values?.vendorList?.map((vendor, index) => renderVendorField({
                ...vendor,
                index,
              }, arrayHelpers))}
              <Box width={200}>
                <Button
                  variant='outlined'
                  onClick={() => arrayHelpers.push({
                    name: '',
                    url: '',
                    description: '',
                  })}
                >
                  Add Another Vendor
                </Button>
              </Box>
            </>
          )}
        />
      )}

    </Stack>
  )
}
