import lang from '../../../lang'

import { StringSchema } from '../..'

export const minimumLength = 8

export default function zipCode(this: StringSchema) {
  return this
    .matches(/^\d{5}(-\d{4})?$/, lang().validation.zipCode)
}
