import validation from '../../../../validation'
import rules from '../../../../validation/rules'

const getParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
}

const getParams = {
  rules: getParamRules,
  schema: validation.schema(getParamRules),
}

export const get = { params: getParams }

const postBodyRules = {
  name: rules.string().required().label('Product Name'),
  annotatorRefId: rules.string().label('Annotator Ref Id'),
  type: rules.string().required().label('Product Type'),
  categories: rules.array().required().of(rules.string().required()).min(1, 'Product category is required')
    .label('Product Categories'),
  policyUrl: rules.string().url().label('Privacy Policy Url'),
  distributionMethods: rules.array().optional().of(rules.string().required())
    .when('type', {
      is: 'Mobile App',
      then: () => rules.array().required().of(rules.string().required())
        .min(1, 'Product distribution method is required if product type is Mobile App.')
        .label('Product Distribution Methods'),
      otherwise: () => rules.array().notRequired(),
    }),
  privacyPolicyIntroduction: rules.string().optional().label('Privacy Policy Introduction'),
}

const postParamRules = { id: validation.rules.string().required().label('Id') }

const postParams = {
  rules: postParamRules,
  schema: validation.schema(postParamRules),
}
const postBody = {
  rules: postBodyRules,
  schema: validation.schema(postBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const post = {
  body: postBody,
  params: postParams,
}

const patchBodyRules = {
  type: rules.string().required().label('Product Type'),
  categories: rules.array().required().of(rules.string().required()).min(1, 'Product category is required')
    .label('Product Categories'),
  distributionMethods: rules.array().optional().of(rules.string().required())
    .when('type', {
      is: 'Mobile App',
      then: () => rules.array().required().of(rules.string().required())
        .min(1, 'Product distribution method is required if product type is Mobile App.')
        .label('Product Distribution Methods'),
      otherwise: () => rules.array().notRequired(),
    }),
  privacyPolicyIntroduction: rules.string().optional().label('Privacy Policy Introduction'),
}

const patchBody = {
  rules: patchBodyRules,
  schema: validation.schema(patchBodyRules),
}

const patchParamRules = {
  id: validation.rules.string().required().label('Organization Id'),
  productId: validation.rules.string().required().label('Product Id'),
}

const patchParams = {
  rules: patchParamRules,
  schema: validation.schema(patchParamRules),
}

export const patch = {
  body: patchBody,
  params: patchParams,
}
