import React, {
  useCallback,
  useState,
} from 'react'
import {
  loadStripe,
  Stripe,
  StripeElements as IStripeElements,
} from '@stripe/stripe-js'
import {
  Elements as StripeElements,
  ElementsConsumer as StripeElementsConsumer,
  PaymentElement as StripePaymentElement,
} from '@stripe/react-stripe-js'

import { lang } from '@common-sense-privacy/common'

import { Box } from '@mui/material'

import useNavigate from '@/hooks/useNavigate'
import useWaitForSubscriptionChange from '@/hooks/useWaitForSubscriptionChange'
import useRefreshAccessToken from '@/hooks/useRefreshAccessToken'
import usePreviousSubscription from '@/hooks/usePreviousSubscription'

import {
  useUpdatePaymentMethodMutation,
  usePostCreateSubscriptionMutation,
} from '@/services/stripe'

import config from '@/config'

import { useOrganizationSubscription } from '@/hooks/useSession'

import { useAlert } from '@/context/AlertContext'

import type {
  Props,
  StripeFormProps,
} from './types'

const stripePromise = loadStripe(config.stripe.publishableKey)

function StripeFormWrapper({
  children,
  clientSecret,
  onSuccessRedirectTo,
  onSuccessMessage,
  type,
  planPriceId,
  couponId,
  ...props
}: Props): React.ReactElement {
  const alert = useAlert()
  if (!clientSecret) {
    alert.setAlert({
      type: 'error',
      description: 'Missing client secret',
    })
  }

  return (
    <StripeElements
      options={{
        clientSecret,
        /**
           * Match the Payment Element with the design of your site with the appearance option.
           * The layout of the Payment Element stays consistent, but you can modify colors, fonts, borders, padding, and more.
           *
           * @docs https://stripe.com/docs/stripe-js/appearance-api
           */
        appearance: {},
      }}
      stripe={stripePromise}
    >
      <StripeForm
        {...props}
        onSuccessRedirectTo={onSuccessRedirectTo}
        onSuccessMessage={onSuccessMessage}
        type={type}
        planPriceId={planPriceId}
        couponId={couponId}
      >
        {children}
      </StripeForm>
    </StripeElements>
  )
}

function StripeForm({
  children,
  onSuccessRedirectTo,
  onSuccessMessage,
  type,
  planPriceId,
  couponId,
  finalize,
  ...props
}: StripeFormProps) {
  const subscription = useOrganizationSubscription()
  const previousSubscription = usePreviousSubscription({ subscription })

  const [ updatePaymentMethod ] = useUpdatePaymentMethodMutation()
  const [ postCreateSubscription ] = usePostCreateSubscriptionMutation()

  const alert = useAlert()
  const navigate = useNavigate()

  const [
    isSubmitting,
    setIsSubmitting,
  ] = useState(false)

  const [
    waitForSubscription,
    setWaitForSubscription,
  ] = useState(false)

  const subscriptionChanged = useWaitForSubscriptionChange({
    canWait: waitForSubscription,
    subscription,
  })

  const accessTokenRefreshed = useRefreshAccessToken(subscriptionChanged)

  if (accessTokenRefreshed) {
    alert.setAlert({
      description: onSuccessMessage(subscription, previousSubscription),
      type: 'success',
    })

    navigate(onSuccessRedirectTo)
  }

  const onSubmit = useCallback(({
    elements,
    stripe,
  }: {
    elements: IStripeElements | null,
    stripe: Stripe | null,
  }) => async () => {
    if (!stripe || !elements) {
      alert.setAlert({
        description: lang().messages.unknownError(),
        type: 'error',
      })

      return
    }

    setIsSubmitting(true)

    if (type === 'submitPayment') {
      const {
        error,
        paymentIntent,
      } = await stripe.confirmPayment({
        confirmParams: { return_url: window.location.href },
        elements,
        redirect: 'if_required',
      })

      if (error) {
        alert.setAlert({
          description: error.message || lang().messages.unknownError(),
          type: 'error',
        })

        setIsSubmitting(false)

        return
      }

      if (subscription?.customer.id && paymentIntent?.payment_method) {
        await updatePaymentMethod({
          body: {
            paymentMethodId: typeof paymentIntent.payment_method === 'string' ? paymentIntent.payment_method : paymentIntent.payment_method.id,
            stripeCustomerId: subscription.customer.id as string,
          },
        })
      }
    }
    else if (type === 'updatePaymentMethod') {
      const {
        error,
        setupIntent,
      } = await stripe.confirmSetup({
        confirmParams: { return_url: window.location.href },
        elements,
        redirect: 'if_required',
      })

      if (error) {
        alert.setAlert({
          description: error.message || lang().messages.unknownError(),
          type: 'error',
        })

        setIsSubmitting(false)

        return
      }

      if (!subscription?.customer.id || !setupIntent?.payment_method) {
        return
      }

      await updatePaymentMethod({
        body: {
          paymentMethodId: typeof setupIntent.payment_method === 'object' ? setupIntent.payment_method.id : setupIntent.payment_method,
          stripeCustomerId: subscription?.customer.id as string,
        },
      })
        .catch(() => {
          alert.setAlert({
            description: lang().messages.unknownError(),
            type: 'error',
          })

          setIsSubmitting(false)
        })
    }

    if (planPriceId) {
      await postCreateSubscription({
        body: {
          priceId: planPriceId,
          couponId,
          finalize: finalize as unknown as boolean,
        },
      })
        .unwrap()
        .then(() => {
          setWaitForSubscription(true)
        }).catch(() => {
          alert.setAlert({
            description: lang().messages.somethingWentWrong(),
            type: 'error',
          })
        })
    }
    else {
      setWaitForSubscription(true)
    }
  }, [
    type,
    planPriceId,
    alert,
    subscription?.customer.id,
    updatePaymentMethod,
    postCreateSubscription,
    couponId,
    finalize,
  ])

  return (
    <StripeElementsConsumer>
      {({
        elements,
        stripe,
      }) => ((!stripe || !elements)) || (
        <Box width='100%'>
          {children({
            isSubmitting,
            onSubmit: onSubmit({
              elements,
              stripe,
            }),
            PaymentForm: (
              <StripePaymentElement
                {...props}
                options={{ terms: { card: 'never' } }}
              />
            ),
          })}
        </Box>
      )}
    </StripeElementsConsumer>
  )
}

export default StripeFormWrapper
