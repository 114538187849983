import React from 'react'

import Loading from '@/screens/Wizard/components/Loading'
import { useGetArticleQuery } from '@/services/article'

import useRouteParams from '@/hooks/useRouteParams'

import Resource from '../components/Resource'

export default function ResourcePublished(): React.ReactElement {
  const { id } = useRouteParams()
  const {
    data: article,
    isLoading,
  } = useGetArticleQuery({ params: { id: id || '' } })

  return (
    <>
      {isLoading && (<Loading />)}
      {(article && article.data) && (<Resource article={article.data} />)}
    </>
  )
}
