import validation from '../../../../validation'
import rules from '../../../../validation/rules'

const postBodyRules = {
  priceId: rules.string().required().label('Price ID'),
  couponId: rules.string().optional().label('Coupon Code'),
  subscriptionId: rules.string().optional().label('Subscription ID'),
}

const postBody = {
  rules: postBodyRules,
  schema: validation.schema(postBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const post = { body: postBody }
