import React from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import { BrowserTracing } from '@sentry/tracing'
import {
  isProduction,
  isTest,
} from '@common-sense-privacy/common'
import { Helmet } from 'react-helmet'

import { isDemo } from '@common-sense-privacy/common/src'

import BrowserRouter from '@/components/BrowserRouter'

import config, { version } from '@/config'

import NewRelicContextProvider from '@/context/NewRelicContext'

import theme from '@/theme'

import log from '@/utils/log'
import Sentry from '@/utils/sentry'

import OnNavigationChange from '@/components/OnNavigationChange'
import MenuContextProvider from '@/components/ScreenWrapper/Header/Menu/context'
import UiProvider from '@/components/UiProvider'

import DevMenu from '@/components/DevMenu'

import Alert from '@/components/Alert'
import { useAlert } from '@/context/AlertContext'
import SignupContextProvider from '@/context/SignupContext'

import Router, { history } from './Router'

if (config.sentryEnabled) {
  if (!config.sentryDsn) {
    log('error', '🐞 Missing sentryDsn in config.')
  }
  else {
    Sentry.init({
      dsn: config.sentryDsn,
      debug: !isProduction,
      environment: config.appEnv,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      release: version,
      tracesSampleRate: 1.0,
    })
  }
}

function App(): React.ReactElement {
  const {
    alert,
    setAlert,
  } = useAlert()

  return (
    <BrowserRouter history={history}>
      <UiProvider theme={theme}>
        <NewRelicContextProvider>
          <MenuContextProvider>
            <SignupContextProvider>
              {!isProduction && !isDemo && !isTest && <DevMenu />}
              {!isProduction ? (
                <Helmet>
                  <script type='text/javascript' src='https://flowerpress.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3o5b4z/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=21323dcd' />
                </Helmet>
              ) : undefined}
              <OnNavigationChange />

              <Router />
              {alert ? (
                <Alert
                  openError={true}
                  onClose={() => {
                    setAlert(undefined)
                  }}
                  type={alert.type}
                  description={alert.description}
                />
              ) : undefined}
            </SignupContextProvider>
          </MenuContextProvider>
        </NewRelicContextProvider>
      </UiProvider>
    </BrowserRouter>
  )
}

export default App
