import { TextWithLinks } from './types'

/*
 * Matches [text](link)
 * Example #1(url):           [click here](https://example.com)
 * Example #2(relative link): [click here](/privacy-policy)
*/
const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g

// eslint-disable-next-line import/prefer-default-export
export const parseTextWithLinks = (text: string): TextWithLinks[] => {
  const parts: TextWithLinks[] = []
  let lastIndex = 0
  let match = linkRegex.exec(text)

  while (match) {
    const linkText = match[1]
    const linkUrl = match[2]
    if (match.index !== lastIndex) {
      parts.push({
        type: 'text',
        value: text.substring(lastIndex, match.index),
      })
    }

    parts.push({
      type: 'link',
      text: linkText,
      url: linkUrl,
    })
    lastIndex = match.index + match[0].length
    match = linkRegex.exec(text)
  }

  if (lastIndex < text.length) {
    parts.push({
      type: 'text',
      value: text.substring(lastIndex),
    })
  }

  return parts
}
