import {
  Container,
  Typography,
} from '@mui/material'
import React from 'react'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import {
  IMPROVE_LEGAL_PRACTICE,
  BEST_PRACTICE_REQUIREMENTS,
  DISCLOSURE_REQUIREMENTS,
  NO_ACTION,
} from '@/services/organization/product/applicableLaw/privacyIssue/types'

import { useGetApplicableLawQuery } from '@/services/organization/product/applicableLaw'
import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'
import useRouteParams from '@/hooks/useRouteParams'

import PrivacyIssuesPreview from './components/PrivacyIssuesPreview'

function LegallySpeakingDetail(): React.ReactElement {
  const { id: organizationId } = useCurrentOrganization()
  const product = useCurrentProduct()
  const { lawId } = useRouteParams()
  const { data } = useGetApplicableLawQuery({
    params: {
      id: organizationId,
      productId: product.id,
      lawId: lawId || '',
    },
  })

  return (
    <DocumentWrapper title='Common Sense Privacy | Dashboard Top Privacy Issues'>
      <ScreenWrapper bgcolor='grey.50'>
        <Container>
          <Typography variant='h1' mb={4}>{data?.data?.abbrev}</Typography>
        </Container>
        <PrivacyIssuesPreview
          state={BEST_PRACTICE_REQUIREMENTS}
          title='Best Practice Required'
          description='The following practices are likely not aligned with minimum legal requirements. You must adhere to best practices.'
          loadMoreText='More Questions'
          itemsPerPage={3}
        />
        <PrivacyIssuesPreview
          state={DISCLOSURE_REQUIREMENTS}
          title='Disclosure Required'
          description='The following practices are likely not aligned with minimum legal requirements. You must disclose your current practices.'
          loadMoreText='More Questions'
          itemsPerPage={3}
        />
        <PrivacyIssuesPreview
          state={IMPROVE_LEGAL_PRACTICE}
          title='Improve Your Score'
          description='The following practices can be improved beyond the minimum requirements.'
          loadMoreText='More Questions'
          itemsPerPage={3}
        />
        <PrivacyIssuesPreview
          state={NO_ACTION}
          title='No Action Needed'
          description='The following practices are clear and likely aligned with law. Nice!'
          loadMoreText='More Questions'
          itemsPerPage={3}
        />
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(LegallySpeakingDetail)
