import validation from '../../../../../validation'

const getListParamsRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
}

const getListParams = {
  rules: getListParamsRules,
  schema: validation.schema(getListParamsRules),
}

const getListQueryRules = {
  retryIfIncomplete: validation
    .rules
    .string()
    .oneOf([
      'false',
      'true',
    ])
    .optional()
    .label('Retry if incomplete?'),
}

const getListQuery = {
  rules: getListQueryRules,
  schema: validation.schema(getListQueryRules),
}

export const getList = {
  params: getListParams,
  query: getListQuery,
}

const getParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
  snapshotId: validation.rules.string().required().label('Snapshot ID'),
}

const getParams = {
  rules: getParamRules,
  schema: validation.schema(getParamRules),
}

export const get = { params: getParams }

const postParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
}

const postParams = {
  rules: postParamRules,
  schema: validation.schema(postParamRules),
}

export const post = { params: postParams }

const postProseGeneratorCallbackBodyRules = {
  sections: validation.rules.array().of(validation.rules.object({
    body: validation.rules.string().label('Body'),
    title: validation.rules.string().label('Title'),
  })),
}

export const postProseGeneratorCallbackBody = {
  rules: postProseGeneratorCallbackBodyRules,
  schema: validation.schema(postProseGeneratorCallbackBodyRules),
}

const postProseGeneratorCallbackParamsRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
  productSnapshotId: validation.rules.string().required().label('ProductSnapshot ID'),
}

export const postProseGeneratorCallbackParams = {
  rules: postProseGeneratorCallbackParamsRules,
  schema: validation.schema(postProseGeneratorCallbackParamsRules),
}
