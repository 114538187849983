import validation from '../../../../validation'
import rules from '../../../../validation/rules'

const postBodyRules = {
  email: rules.string().email().required().label('Email'),
  'g-recaptcha-response': rules.string(),
}

const postBody = {
  rules: postBodyRules,
  schema: validation.schema(postBodyRules),
}

const patchBodyRules = {
  email: rules.string().email().required().label('Email'),
  password: rules.string().password().required().label('Password'),
  confirmPassword: rules.string().password().required().oneOf([ rules.ref('password') ], 'Passwords must match')
    .label('Confirm Password'),
}

const patchBody = {
  rules: patchBodyRules,
  schema: validation.schema(patchBodyRules),
}

const patchQueryRules = { token: rules.string().required() }

const patchQuery = {
  rules: patchQueryRules,
  schema: validation.schema(patchQueryRules),
}

// eslint-disable-next-line import/prefer-default-export
export const post = { body: postBody }

export const patch = {
  body: patchBody,
  query: patchQuery,
}
