export default {
  messages: {
    changesSaved: () => 'Your changes have been saved.',
    credentialIncorrect: () => 'The credentials you entered are incorrect.',
    accountCanceled: (contactUrl: string) => `Oops! You are trying to sign in with an email associated with a canceled account. To reactivate your account please [Contact Support](${contactUrl}).`,
    genericErrorHeading: () => 'Oops!',
    genericSuccessHeading: () => 'Success',
    incorrectCode: () => 'Incorrect code.',
    invalidCouponCode: () => 'Invalid coupon code.',
    loginIncorrect: () => 'Incorrect email address or password.',
    passwordResetCodeExpired: () => 'Your code has expired. Please request a new code.',
    passwordResetCodeInvalid: () => 'Invalid reset code.',
    permissionDenied: () => 'You do not have permission to do that.',
    sentEmail: () => 'We have sent you an email. Please check your inbox.',
    unauthorized: () => 'You are not logged in.',
    unableToFindEmail: (email: string) => `We were unable to find a user with the email ${email}. Please try again.`,
    unableToResetPassword: () => 'We were unable to reset your password.',
    userCreated: () => 'User created.',
    userCreationFailed: () => 'Unable to create user.',
    contactInformationUpdated: () => 'Contact information updated.',
    contactInformationUpdateFailed: () => 'Unable to update contact information.',
    notificationSettingUpdated: () => 'Notification setting is updated.',
    notificationSettingUpdateFailed: () => 'Unable to update notification setting.',
    notFound: (item?: string) => item ? `${item} not found.` : 'Not found.',
    userEmailNotFound: () => 'Your email was not found',
    userEmailVerified: () => 'Your email has been verified.',
    userEmailAlreadyVerified: () => 'This email has already been verified. Your account is active and ready to use.',
    userNotFound: () => 'User not found.',
    userDeleted: () => 'User deleted.',
    userPasswordUpdateSuccessful: () => 'Your password has been reset. Please sign in to continue.',
    unknownError: () => 'An unknown error has occurred.',
    verificationCodeSent: () => 'We have sent you an email. Please check your inbox.',
    verificationCodeExpired: () => 'Verification link expired. Please request a new one.',
    forbidden: () => 'Forbidden',
    passwordIncorrect: () => 'The password you entered is incorrect.',
    passwordsDoNotMatch: () => 'Passwords do not match',
    passwordUpdated: () => 'Password successfully updated',
    organizationNotFound: () => 'Organization not found',
    organizationUpdated: () => 'Organization updated',
    sessionExpired: () => 'Your session has expired. Please log back in.',
    productCreated: () => 'Product created.',
    productUpdated: () => 'Product updated.',
    productNotFound: () => 'Product not found',
    productAlreadyExists: () => 'Product already exists',
    refreshTokenExpired: () => 'Refresh token expired.',
    topicNotFound: () => 'Privacy Topic not found',
    contactInquirySuccess: () => 'Success! Your message has been sent.',
    contactInquiryFail: (email: string) => `Your request failed. If you continue to encounter this error, please contact us at ${email}`,
    failedToGeneratePdf: () => 'Failed to generate pdf.',
    somethingWentWrong: () => 'Something went wrong. Please try again later.',
    planEnrollmentSuccess: (plan: string) => `Success! You have been enrolled in the ${plan} plan`,
    switchPlanEntrollmentSuccess: (newPlan: string, newPlanPrice: string, oldPlan: string, oldPlanDate: string) => `You enrolled in the ${newPlan} plan for ${newPlanPrice}. You will maintain access to features in your ${oldPlan} plan until ${oldPlanDate}`,
    editPaymentSuccess: () => 'Success! Your new payment information has been saved.',
  },
  validation: {
    base64ImageInvalid: () => 'Base64 image is invalid',
    emailAlreadyTaken: () => 'Email is already taken.',
    signupAccountCanceled: (contactUrl: string) => `Oops! You are trying to sign up with an email associated with a canceled account. To reactivate your account please [Contact Support](${contactUrl}).`,
    emailNotFound: () => 'Email not found.',
    roleNotFound: () => 'Role not found.',
    fieldIsNotAllowed: (name?: string) => name ? `${name} is not allowed.` : 'One of the fields passed is not allowed.',
    invalidDate: () => 'Date must be formatted yyyy-mm-dd',
    invalidDatetime: () => 'Datetime must be formatted yyyy-mm-dd hh:mm:ss(.mmmm)',
    invalidTime: () => 'Time must be formatted hh:mm:ss(.mmmm)',
    passwordHelperText: () => 'Include a minimum of 8 characters made up of numbers, letters and a special character.',
    passwordLowerCaseLetterRequired: () => 'Password must have at least one lower-case letter.',
    passwordNumerRequired: () => 'Password must have at least one number.',
    passwordSpecialCharacterRequired: () => 'Password must have at least one special character.',
    passwordMinimumLength: (min: number) => `Password must be at least ${min.toString()} characters long.`,
    passwordsMustMatch: () => 'Passwords must match.',
    phoneNumberUs: () => 'Phone number must be in format 000-000-0000.',
    tradeCategoryNotFound: () => 'Trade Category not found.',
    userRoleNotFound: () => 'User Role not found.',
    validationFailed: () => 'Validation failed.',
    verificationCodeFormatWrong: () => 'Verification Code must be exactly 6 digits.',
    zipCode: () => 'Zip code must be in format 00000-0000 or 00000.',
    email: (isOrganization?: boolean) => `${isOrganization ? 'The email needs to follow this format: name@company.com' : 'Enter a valid email address.'}`,
    integer: () => 'Must be an integer value',
  },
}
