"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Category = void 0;
var Category;
(function (Category) {
    Category["HIGH_PRIORITY_FIX"] = "high-priority-fix";
    Category["TOP_PRIORITY"] = "top-priority";
    Category["IMPROVE_PRACTICE"] = "improve-practice";
    Category["EASY_FIX"] = "easy-fix";
})(Category || (exports.Category = Category = {}));
