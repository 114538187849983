import {
  Container,
  Divider,
  Typography,
  Stack,
  Alert,
  Box,
  Button,
  Breadcrumbs,
  Link,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import React, {
  useCallback,
  useEffect, useState,
} from 'react'

import {
  useLocation,
  useNavigate,
} from 'react-router-dom'

import {
  lang,
  api,
  url,
} from '@common-sense-privacy/common'

import {
  useGetPlansQuery,
  usePostCreatePaymentPreviewMutation,
  usePostGetCouponMutation,
} from '@/services/stripe'
import { UpCommingInvoice } from '@/services/stripe/types'

import { useStripe } from '@/context/StripeContext'
import { useAlert } from '@/context/AlertContext'
import { useOrganizationSubscription } from '@/hooks/useSession'
import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'
import BackLink from '@/components/BackLink'
import Loading from '@/screens/Wizard/components/Loading'
import StripeFormWrapper from '@/components/StripeFormWrapper'
import Form from '@/components/Form'
import FormField from '@/components/FormField'

import {
  transformPlan,
  getPlanName,
  getPlanType,
  getSubscriptionExpiration,
  getSubscriptionPriceInfo,
  isStarterPlan,
} from '@/utils/stripe'
import { Subscription } from '@/utils/stripe/types'

import dateTime from '@/utils/dateTime'

import type {
  FormValues,
  OnSubmit,
} from './types'

function SubscriptionsPayment(): React.ReactElement {
  const { stripe } = useStripe()
  const {
    state,
    search,
  } = useLocation()
  const finalize = search.includes('finalize') as unknown as string | undefined
  const {
    clientSecret,
    planId,
    priceId,
    editMode,
    nextPage,
  } = state
  const navigate = useNavigate()
  const alert = useAlert()
  const handleFormApiErrors = useHandleFormApiErrors()

  const [
    isCouponPopupOpen,
    setIsCouponPopupOpen,
  ] = useState(false)
  const [
    previewInvoice,
    setPreviewInvoice,
  ] = useState<UpCommingInvoice | undefined>(undefined)

  const subscription = useOrganizationSubscription()
  const { data: plansResponse } = useGetPlansQuery(undefined, { refetchOnMountOrArgChange: true })
  const newPlan = plansResponse?.data?.find(plan => plan.id === planId)
  const transformedNewPlan = newPlan && transformPlan({ plan: newPlan })
  const currentPlanType = subscription && getPlanType(subscription)

  const [
    fetchCoupon,
    { isLoading: isFetchingCoupon },
  ] = usePostGetCouponMutation()
  const [
    getPreviewInvoice,
    { isLoading: isLoadingPreviewInvoice },
  ] = usePostCreatePaymentPreviewMutation()

  const fetchPreviewInvoice = useCallback((couponId: string | undefined = undefined) => {
    getPreviewInvoice({
      body: {
        priceId,
        couponId,
        subscriptionId: subscription?.id,
      },
    })
      .unwrap()
      .then(response => {
        const paymentPreview = response.data
        setPreviewInvoice(paymentPreview)
      }).catch(error => {
        alert.setAlert({
          type: 'error',
          description: error.data.message,
        })
      })
  }, [
    getPreviewInvoice,
    priceId,
    subscription,
    alert,
  ])

  useEffect(() => {
    if (!previewInvoice && !isLoadingPreviewInvoice) {
      fetchPreviewInvoice()
    }
  }, [
    previewInvoice,
    isLoadingPreviewInvoice,
    getPreviewInvoice,
    priceId,
    alert,
    fetchPreviewInvoice,
  ])

  const { rules } = api.endpoints.stripe.coupons.validation.post.body
  const amount = (previewInvoice?.amount_due || 0) / 100

  const isPlanUpgrade = (
    subscription: Subscription | undefined,
  ) => (transformedNewPlan?.order || 0) > (subscription?.plan.product.metadata.order || 0)

  const isNewPlanPriceAmountProrated = () => (transformedNewPlan?.priceAmount || 0) > (previewInvoice?.amount_due || 0)
  const generateOnSuccessMessage = (subscription: Subscription | undefined, previousSubscription: Subscription | undefined) => {
    const planType = subscription && getPlanType(subscription)
    const previousPlanType = previousSubscription && getPlanType(previousSubscription)
    if (subscription && previousSubscription && previousPlanType && !isStarterPlan(previousPlanType) && planType !== previousPlanType) {
      const newPlan = getPlanName(subscription)
      const oldPlan = getPlanName(previousSubscription)
      const expires = getSubscriptionExpiration(subscription)
      const priceInfo = getSubscriptionPriceInfo(subscription)
      const price = priceInfo.isFree ? priceInfo.price : `${amount}/${priceInfo.billingInterval}`
      const originalAmount = transformedNewPlan?.price

      if (isPlanUpgrade(previousSubscription)) {
        const isProratedPrice = isNewPlanPriceAmountProrated()
        if (isProratedPrice) {
          const currentDate = dateTime.format(new Date(), 'MM.dd.yyyy')

          return `You enrolled in the ${transformedNewPlan?.name} plan for ${originalAmount}/${transformedNewPlan?.billingInterval}. ${
            currentPlanType && !isStarterPlan(currentPlanType) && ` You paid the prorated price of $${amount} on ${currentDate} and then ${originalAmount}/year following that.`}`
        }

        return `You enrolled in the ${transformedNewPlan?.name} plan for ${originalAmount}/${transformedNewPlan?.billingInterval}. `
      }

      return lang().messages.switchPlanEntrollmentSuccess(newPlan, price, oldPlan, expires)
    }
    if (subscription) {
      return lang().messages.planEnrollmentSuccess(getPlanName(subscription))
    }

    return lang().messages.changesSaved()
  }

  const getPriceInformationInfo = () => {
    if (isLoadingPreviewInvoice) {
      return ''
    }
    const originalAmount = transformedNewPlan?.price
    if (isPlanUpgrade(subscription)) {
      const isProratedPrice = isNewPlanPriceAmountProrated()
      if (isProratedPrice) {
        const currentDate = dateTime.format(new Date(), 'MM.dd.yyyy')

        return (
          <Typography variant='h6'>
            {`You are enrolling in the ${transformedNewPlan?.name} plan for ${originalAmount}/${transformedNewPlan?.billingInterval}. `}
            {currentPlanType && !isStarterPlan(currentPlanType) && ` You will pay the prorated price of $${amount} on ${currentDate} and then ${originalAmount}/year following that.`}
          </Typography>
        )
      }

      return (
        <Typography variant='h6'>
          {`You are enrolling in the ${transformedNewPlan?.name} plan for ${originalAmount}/${transformedNewPlan?.billingInterval}. `}
        </Typography>
      )
    }

    return (
      <Typography variant='h6'>
        {`You are enrolling in the ${transformedNewPlan?.name} plan for $${amount}/${transformedNewPlan?.billingInterval}. `}
        {currentPlanType && !isStarterPlan(currentPlanType) && `You will maintain access to features in your ${getPlanName(subscription)} plan until ${getSubscriptionExpiration(subscription)}.`}
      </Typography>
    )
  }

  const handleApplyCoupon: OnSubmit = (values: FormValues, { setErrors }) => {
    fetchCoupon({ body: values })
      .unwrap()
      .then(response => {
        const coupon = response.data
        if (coupon) {
          alert.setAlert({
            type: 'success',
            description: 'Coupon Applied Successfully',
          })
          fetchPreviewInvoice(coupon.id)
          setIsCouponPopupOpen(false)
        }
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }

  const isPreviewAmountGreaterThanZero = !!(transformedNewPlan && previewInvoice && previewInvoice?.amount_due > 0)
  const hasDiscountApplied = !!previewInvoice?.discount?.coupon?.id
  const paymentFormLoading = !stripe || isLoadingPreviewInvoice

  return (
    <DocumentWrapper title='Common Sense Privacy | Payment'>
      <ScreenWrapper>
        <Container>
          <Stack mb={4} spacing={0.5} direction='row'>
            <Box mb={4}>
              {editMode ? (
                <Breadcrumbs>
                  <Link
                    href='/account'
                    variant='body2'
                    sx={{ color: 'text.primary' }}
                  >My Account
                  </Link>
                  <Link
                    href='/account/edit-plan'
                    variant='body2'
                    sx={{ color: 'text.primary' }}
                  >Edit Plan
                  </Link>
                  <Typography variant='body2'>Payment</Typography>
                </Breadcrumbs>
              ) : (
                <BackLink
                  to=''
                  onClick={e => {
                    e.preventDefault()
                    navigate(-1)
                  }}
                />
              )}
            </Box>
          </Stack>
          <Stack mb={4}>
            <Typography variant='h1'>Payment Method</Typography>
            <Typography variant='intro' mb={4}>{editMode ? 'Enter your payment information to update your plan.' : 'Please enter your payment information to continue.'}</Typography>
            <Divider />
          </Stack>
        </Container>
        {paymentFormLoading && (<Loading />)}
        {!paymentFormLoading && (
          <Container>
            <Stack alignItems='flex-start'>
              <Alert color='success' icon={false}>
                {isPreviewAmountGreaterThanZero && (
                  <>
                    {getPriceInformationInfo()}
                    <Typography>
                      Do you have a
                      <Button
                        variant='text'
                        onClick={() => setIsCouponPopupOpen(true)}
                        color='inherit'
                        sx={{
                          cursor: 'pointer',
                          marginBottom: '0.2rem',
                          paddingLeft: '5px',
                        }}
                      >coupon code
                      </Button>?
                    </Typography>
                  </>
                )}
                {(!isPreviewAmountGreaterThanZero && hasDiscountApplied) && (
                  <Typography variant='h6'>
                    The coupon code has been accepted.
                  </Typography>
                )}
              </Alert>
            </Stack>

            <Stack mt={4} maxWidth={640}>

              {stripe && (
                <StripeFormWrapper
                  clientSecret={clientSecret}
                  onSuccessRedirectTo={nextPage || '/wizard'}
                  onSuccessMessage={generateOnSuccessMessage}
                  type='updatePaymentMethod'
                  planPriceId={priceId}
                  couponId={previewInvoice?.discount?.coupon?.id}
                  finalize={finalize}
                >
                  {({
                    isSubmitting,
                    onSubmit,
                    PaymentForm,
                  }) => (
                    <Form onSubmit={onSubmit}>
                      {PaymentForm}
                      <Stack direction='column' mt={2}>
                        <Typography variant='caption'>
                          By providing your card information, you allow Common Sense Privacy to charge your card for future payments in accordance with their <Link href={url.marketingSite('/terms-of-service')} variant='caption' color='inherit'>terms</Link>.
                        </Typography>
                        <Stack direction='row' spacing={2} mt={2}>
                          <Button type='submit' disabled={isSubmitting}>Pay Now</Button>
                        </Stack>
                      </Stack>
                      <Dialog open={isSubmitting}>
                        <DialogContent>
                          <Typography variant='h5'>We are processing your payment.</Typography>
                          <Loading />
                        </DialogContent>
                      </Dialog>
                    </Form>
                  )}
                </StripeFormWrapper>
              )}
            </Stack>
            <Dialog open={isCouponPopupOpen} onClose={() => setIsCouponPopupOpen(false)}>
              <Form<FormValues>
                initialValues={{ couponCode: '' }}
                onSubmit={handleApplyCoupon}
                rules={rules}
              >
                <DialogTitle>
                  <Typography variant='h5'>Enter your coupon code to get a discount</Typography>
                </DialogTitle>
                <DialogContent>
                  <Box textAlign='center' sx={{ mt: 2 }}>
                    <FormField
                      name='couponCode'
                      label='Coupon Code'
                      variant='outlined'
                      type='text'
                      size='small'
                      disabled={isFetchingCoupon}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{ 'data-testid': 'coupon-code' }}
                    />

                  </Box>
                </DialogContent>
                <DialogActions>
                  <Stack spacing={2} direction='row' justifyContent='center'>
                    <Button variant='cancel' disabled={isFetchingCoupon} onClick={() => setIsCouponPopupOpen(false)} sx={{ width: 'auto' }}>Cancel</Button>
                    <Button variant='contained' type='submit' disabled={isFetchingCoupon}>Apply Coupon Code</Button>
                  </Stack>
                </DialogActions>
              </Form>
            </Dialog>

          </Container>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SubscriptionsPayment)
