import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material'

import { useDispatch } from 'react-redux'

import { api } from '@common-sense-privacy/common'

import { Link as RouterLink } from 'react-router-dom'

import Form from '@/components/Form'
import FormField from '@/components/FormField'

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'

import { usePostLoginMutation } from '@/services/auth'
import { setSession } from '@/state/slices/session'
import useNavigate from '@/hooks/useNavigate'

import ScreenWrapper from '../../components/ScreenWrapper'
import DocumentWrapper from '../../components/DocumentWrapper'

import type {
  FormValues,
  OnSubmit,
} from './types'

function SignIn(): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()

  const [
    postLogin,
    { isLoading },
  ] = usePostLoginMutation()

  const initialValues = {
    email: '',
    password: '',
  }
  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    postLogin({ body: values })
      .unwrap().then(response => {
        dispatch(setSession(response.data))
        navigate('/wizard')
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    dispatch,
    handleFormApiErrors,
    navigate,
    postLogin,
  ])
  const { rules } = api.endpoints.auth.validation.post.body

  return (
    <DocumentWrapper title='Common Sense Privacy | Sign In'>
      <ScreenWrapper>
        <Container maxWidth='sm'>
          <Typography variant='h5' textAlign='center'>Sign in to your account</Typography>
        </Container>
        <Container maxWidth='sm'>
          <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={rules}>
            <Stack maxWidth='sm' marginTop={4} spacing={4}>
              <FormField
                name='email'
                label='Email'
                variant='outlined'
                inputProps={{ 'data-testid': 'signin-email' }}
              />
              <FormField
                name='password'
                type='password'
                label='Password'
                variant='outlined'
                inputProps={{ 'data-testid': 'signin-password' }}
              />

              <Box textAlign='center'>
                <Button
                  variant='contained'
                  disabled={isLoading}
                  type='submit'
                  role='button'
                >
                  Sign In
                </Button>
              </Box>
              <Box textAlign='center'>
                <Link component={RouterLink} to='/forgot-password' variant='body1'>Forgot Password</Link>
              </Box>
            </Stack>
          </Form>

        </Container>
        <Container>
          <Stack spacing={4} mt={6} textAlign='center'>
            <Divider />
            <Typography variant='h6'>Don’t have an account yet?</Typography>
            <Box>
              <Button component={RouterLink} to='/signup' variant='contained'>Sign Up</Button>
            </Box>
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SignIn)
