import { useSelector } from 'react-redux'

import {
  productInformationSelectors,
  ProductInfo,
} from '../../state/slices/productInformation'
import { useCurrentOrganization } from '../useSession'

function useProductInformation() {
  const productInformation = useSelector(productInformationSelectors.productInformation)

  return productInformation || {}
}

export function useHasExistingPolicy(): boolean {
  const organization = useCurrentOrganization()

  return organization.hasProductPolicies === 1
}

export function usePolicyUrl(): string {
  const productInformation = useProductInformation()

  return productInformation.policyUrl
}

export function useProductInfo(): ProductInfo {
  const productInformation = useProductInformation()

  return productInformation.productInfo || {
    name: {
      annotatorRefId: '',
      name: '',
    },
    type: '',
    categories: [],
    distributionMethods: [],
    privacyPolicyIntroduction: '',
  }
}

export default useProductInformation

export * from '../../state/slices/productInformation'
