import validation from '../../../validation'
import rules from '../../../validation/rules'

const postBodyRules = {
  isLoggedInAndVerified: rules.boolean().required(),
  firstName: rules.string().sanitizeForHTMLJS().label('First Name').when('isLoggedInAndVerified', {
    is: false,
    then: schema => schema.required().max(50, 'You used too many characters. Please reduce the number of charactes to fit the limit of 50 characters.'),
  }),
  lastName: rules.string().sanitizeForHTMLJS().label('Last Name').when('isLoggedInAndVerified', {
    is: false,
    then: schema => schema.required().max(50, 'You used too many characters. Please reduce the number of charactes to fit the limit of 50 characters.'),
  }),
  email: rules.string().sanitizeForHTMLJS().email().label('Email')
    .when('isLoggedInAndVerified', {
      is: false,
      then: schema => schema.required(),
    }),
  topic: rules.string().sanitizeForHTMLJS().required().max(50, 'You used too many characters. Please reduce the number of charactes to fit the limit of 50 characters.')
    .label('Topic'),
  inquiry: rules.string().sanitizeForHTMLJS().required().max(500, 'You used too many characters. Please reduce the number of charactes to fit the limit of 500 characters.')
    .label('Inquiry'),
}

const postBody = {
  rules: postBodyRules,
  schema: validation.schema(postBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const post = { body: postBody }
