import lang from '../../../lang'

import validation, { StringSchema } from '../..'

export default function phoneNumber(this: StringSchema, { allowInternational }: { allowInternational?: boolean } = { allowInternational: false }) {
  if (allowInternational) {
    throw new validation.ValidationError('International numbers not yet supported')
  }

  return this
    .matches(/^\d{3}-\d{3}-\d{4}$/, lang().validation.phoneNumberUs)
}
