import lang from '../../../lang'

import { StringSchema } from '../..'

export default function numeric(this: StringSchema) {
  /* eslint-disable */
  return this.matches(
    /^\d+$/,
    lang().validation.integer()
  )
  /* eslint-enable */
}
