"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicableLawState = void 0;
var ApplicableLawState;
(function (ApplicableLawState) {
    ApplicableLawState["NO_ACTION"] = "noAction";
    ApplicableLawState["BEST_PRACTICE_REQUIREMENTS"] = "bestPracticeRequirements";
    ApplicableLawState["IMPROVE_LEGAL_PRACTICE"] = "improveLegalPractice";
    ApplicableLawState["DISCLOSURE_REQUIREMENTS"] = "disclosureRequirements";
})(ApplicableLawState || (exports.ApplicableLawState = ApplicableLawState = {}));
