import validation from '../../../validation'
import rules from '../../../validation/rules'

const deleteBodyRules = {
  password: rules.string().required().label('Password'),
  confirmPassword: rules.string().required().oneOf([ rules.ref('password') ], 'Passwords must match').label('Confirm Password'),
}

const deleteBody = {
  rules: deleteBodyRules,
  schema: validation.schema(deleteBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const deleteUser = { body: deleteBody }
