/**
 * NOTE: These values are stored in .env files IN APP WORKSPACES, not in this package
 */

import env from './env'

import isTest from './isTest'

export default {
  apiBaseUrl: process.env.API_BASE_URL || process.env.REACT_APP_API_BASE_URL || '',
  appEnv: env.get<string>('APP_ENV'),
  assetsBaseUrl: process.env.ASSETS_BASE_URL || process.env.REACT_APP_ASSETS_BASE_URL || '',
  isDebug: env.get<boolean>('APP_DEBUG', 'bool', false),
  marketingSiteBaseUrl: process.env.MARKETING_SITE_BASE_URL || process.env.REACT_APP_MARKETING_SITE_BASE_URL || 'commonsenseprivacy.net',
  newRelic: {
    isEnabled: !isTest && env.get<boolean>('NEW_RELIC_ENABLED', 'bool', false),
    licenseKey: env.get<string>('NEW_RELIC_LICENSE_KEY'),
  },
  sentryDsn: env.get<string>('SENTRY_DSN'),
  sentryEnabled: !isTest && env.get<boolean>('SENTRY_ENABLED', 'bool', false),
  webAppBaseUrl: process.env.WEB_APP_BASE_URL || process.env.REACT_APP_WEB_APP_BASE_URL || '',
  stripe: {
    secretKey: process.env.STRIPE_SECRET_KEY,
    webhookSecret: process.env.STRIPE_WEBHOOK_SECRET,
  },
}
