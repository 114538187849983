import validation from '../../../validation'

const getQueryRules = { search: validation.rules.string().required().label('Search Term') }

const getParams = {
  rules: getQueryRules,
  schema: validation.schema(getQueryRules),
}

// eslint-disable-next-line import/prefer-default-export
export const get = { query: getParams }
