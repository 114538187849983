import validation from '../../../../validation'
import rules from '../../../../validation/rules'

const patchBodyRules = {
  firstName: rules.string().label('First Name').required(),
  lastName: rules.string().label('Last Name').required(),
  organizationRoles: rules.array().of(rules.string().required()).min(1, 'Roles are required').label('Roles')
    .required(),
  jobTitle: rules.string().label('Job Title').optional(),
}

const patchBody = {
  rules: patchBodyRules,
  schema: validation.schema(patchBodyRules),
}

// eslint-disable-next-line import/prefer-default-export
export const patch = { body: patchBody }
