import validation from '../../../../../validation'

const getParamRules = {
  id: validation.rules.string().required().label('Organization ID'),
  productId: validation.rules.string().required().label('Product ID'),
}

const getQueryRules = {
  metric: validation.rules.array().of(validation.rules.string().oneOf([
    'score',
    'legallySpeaking',
    'topPrivacyIssues',
    'topPrivacyIssuesScore',
    'benchmarkScore',
  ]).required()).required().min(1)
    .label('Metric'),
}

const getQuery = {
  rules: getQueryRules,
  schema: validation.schema(getQueryRules),
}

const getParams = {
  rules: getParamRules,
  schema: validation.schema(getParamRules),
}

// eslint-disable-next-line import/prefer-default-export
export const get = {
  params: getParams,
  query: getQuery,
}
