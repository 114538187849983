import React from 'react'

import DocumentWrapper from '../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../components/ScreenWrapper'

import ResourcePreview from './Preview'

import ResourcePublished from './Published'

function DashboardResourcesDetail({ preview = false }: { preview?: boolean }): React.ReactElement {
  return (
    <DocumentWrapper title='Common Sense Privacy | Dashboard Resources Detail'>
      <ScreenWrapper bgcolor='grey.50'>
        {!preview && (<ResourcePublished />)}
        {preview && (<ResourcePreview />)}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DashboardResourcesDetail)
