import React from 'react'

import { useLocation } from 'react-router-dom'

import useNavigate from '@/hooks/useNavigate'
import { useCurrentProduct } from '@/hooks/useSession'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'
import Plans from '@/components/Plans'

function SubscriptionsPlans(): React.ReactElement {
  const { id: productId } = useCurrentProduct()
  const navigate = useNavigate()
  const {
    state,
    search,
  } = useLocation()
  const requiresPaidPlan = state?.requiresPaidPlan
  const finalize = search.includes('finalize')

  return (
    <DocumentWrapper title='Common Sense Privacy | Plans'>
      <ScreenWrapper>
        <Plans
          subtitle={requiresPaidPlan ? 'You must have a paid plan to download your privacy policy' : 'Choose a plan to continue'}
          requiresPaidPlan={requiresPaidPlan}
          finalize={finalize}
          onSelectFreePlan={!requiresPaidPlan && !productId ? () => navigate('/wizard/product/information') : undefined}
          backLink={requiresPaidPlan ? `/wizard/products/${productId}/finalize` : undefined}
          onSuccessLink={requiresPaidPlan && '/privacy-policies'}
        />
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SubscriptionsPlans)
